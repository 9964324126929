import React from 'react'
import Layout from '../../../components/common/pageLayout'
import Form from '../../../components/Administration/users/form'
import { v3_add_user } from '../../../actions/v3/user'
import constant from '../../../constant'
import { toast } from 'react-toastify'
const Add = (props) => {
    const onSubmit = (model) => {
        v3_add_user(model).then((res) => {
            if (res.status) {
                toast.success(res.result.message)
                props.history.push(constant.Authedcomponent.listUser.url)
            }
            else {
                toast.error(res.error)
            }
        })
    }
    return (
        <React.Fragment>
            <Layout
                title={'Add Admin User'}>
                <Form
                    title={'Add Admin User'}
                    onSubmit={onSubmit}
                    buttonName={'Create User'}
                />
            </Layout>

        </React.Fragment>
    )
}

export default Add