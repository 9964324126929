import React from 'react'
import Layout from '../../components/common/pageLayout'
import Form from '../../components/Voucher/add'
import constant from '../../constant'
import { toast } from 'react-toastify'
import { v3_add_voucher_step_one } from '../../actions/v3/voucherManagement'
const Add = (props) => {

    const onSubmit = (model) => {
        v3_add_voucher_step_one(model).then((res) => {
            if (res.status) {
                toast.success(res.result.message)
                props.history.push(constant.Authedcomponent.assignVoucher.url)
            }
            else {
                toast.error(res.error)

            }
        })
    }

    const onCancel = () => {
        props.history.push(constant.Authedcomponent.listVoucher.url)
    }
    return (
        <React.Fragment>
            <Layout
                title={'Create Voucher'}
            >
                <Form
                    onCancel={onCancel}
                    title={'Create Voucher'}
                    onSubmit={onSubmit}
                    buttonName={'Create Voucher'}
                />
            </Layout>
        </React.Fragment>
    )
}

export default Add