import React, { useState } from 'react'
import { v2_roles } from '../../../actions/v2/masterData';
import { toast } from 'react-toastify';
import { validateFunctionalForm } from '../../../utils';

const Add = (props) => {
    const [state, setState] = useState({
        fullName: { name: 'fullName', value: props.data ? props.data.FULL_NAME : '', isRequried: true, error: '' },
        userName: { name: 'userName', value: props.data ? props.data.USER_NAME : '', isRequried: true, error: '' },
        role: { name: 'role', value: props.data ? props.data.ROLE_ID : '', options: [], isRequried: true, error: '' },
        email: { name: 'email', value: props.data ? props.data.EMAIL_ID : '', isRequried: true, error: '' },
        phone: { name: 'phone', value: props.data ? props.data.MOBILE : '', isRequried: true, error: '' },
        password: { name: 'password', value: '', isRequried: props.data ? false : true, error: '' },
        otherDetails: { name: 'otherDetails', value: props.data ? props.data.OTHER_DETAILS : '', isRequried: false, error: '' },
    })
    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value,
                error: prevState[name].isRequired && !value ? 'This field is required' : ''
            }
        }));
    };

    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    };

    useState(() => {
        v2_roles().then(res => {
            if (res.status) {
                setOptions('role', res.result);
            }
            else {
                toast.error(res.error);
            }
        })
    }, [])

    const onSubmit = (e) => {
        e.preventDefault();
        if (validateFunctionalForm(state, setState)) {
            const model = {
                fullName: state.fullName.value,
                userName: state.userName.value,
                role: state.role.value,
                email: state.email.value,
                phone: state.phone.value,
                password: state.password.value,
                otherDetails: state.otherDetails.value,
            }
            if (props.data) {
                model.userId = props.data.USER_ID
            }
            props.onSubmit(model)
        }
    }
    return (
        <React.Fragment>
            <h4 className="card-title mb-4">{props.title}</h4>
            <div id="basic-example">
                <section>
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-firstname-input">
                                        Full name <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="basicpill-firstname-input"
                                        placeholder="Enter Full Name"
                                        name={state.fullName.name}
                                        value={state.fullName.value}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-lastname-input">
                                        User name <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="basicpill-lastname-input"
                                        placeholder="Enter User Name"
                                        name={state.userName.name}
                                        value={state.userName.value}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-phoneno-input">
                                        Phone <span className='text-danger'>*</span>

                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="basicpill-phoneno-input"
                                        placeholder="Enter Phone No."
                                        name={state.phone.name}
                                        value={state.phone.value}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-phoneno-input">Role <span className='text-danger'>*</span></label>
                                    <select className="form-select"
                                        name={state.role.name}
                                        value={state.role.value}
                                        onChange={onChange}
                                    >
                                        <option value=''>Select Role</option>
                                        {state.role.options.map((item) => {
                                            return (
                                                <option value={item.ROLE_ID}>{item.ROLE_NAME}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div
                                className="col-lg-3"
                                style={{ background: "#fffafa" }}
                            >
                                <div className="mb-3">
                                    <label htmlFor="basicpill-email-input">Email <span className='text-danger'>*</span></label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="basicpill-email-input"
                                        placeholder="Enter Email ID"
                                        name={state.email.name}
                                        value={state.email.value}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div
                                className="col-lg-3"
                                style={{ background: "#fffafa" }}
                            >
                                <div className="mb-3">
                                    <label htmlFor="basicpill-email-input">
                                        Password {state.password.isRequried && <span className='text-danger'>*</span>}
                                    </label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="basicpill-email-input"
                                        placeholder=""
                                        name={state.password.name}
                                        value={state.password.value}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-address-input">
                                        Other Details <span className='text-danger'>*</span>
                                    </label>
                                    <textarea
                                        id="basicpill-address-input"
                                        className="form-control"
                                        rows={2}
                                        placeholder="Enter Other Details"
                                        defaultValue={""}
                                        name={state.otherDetails.name}
                                        value={state.otherDetails.value}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <button
                                        type='submit'
                                        className="btn btn-success waves-effect btn-label waves-light me-2"
                                    >
                                        <i className="bx bx-check-double label-icon" />{" "}
                                        {props.buttonName}
                                    </button>
                                    <button
                                        type='reset'
                                        className="btn btn-danger waves-effect btn-label waves-light"
                                    >
                                        <i className="bx bx-block label-icon " /> Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </React.Fragment>

    )
}

export default Add