import React from 'react'
import Layout from '../../../components/common/pageLayout'
import Form from '../../../components/MasterData/CompanyIndustry/add'
import { v3_add_company_industry } from '../../../actions/v3/masterData'
import constant from '../../../constant'
import { toast } from 'react-toastify'
const Add = (props) => {

    const onSubmit = (model) => {
        v3_add_company_industry(model).then((res) => {
            if (res.status) {
                toast.success(res.result.message)
                props.history.push(constant.Authedcomponent.listCompanyIndustryMasterData.url)
            }
            else {
                toast.error(res.error)

            }
        })
    }
    return (
        <React.Fragment>
            <Layout
                title={'Add Company Industry'}
            >
                <Form
                    title={'Add Company Industry'}
                    onSubmit={onSubmit}
                    buttonName={'Create Company Industry'}
                />
            </Layout>
        </React.Fragment>
    )
}

export default Add