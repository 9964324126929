import axios from "axios";
import { getAuthHeader } from "../../utils";

export const v3_add_warehouse = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/warehouse`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_warehouses = async (page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/warehouse/${page}/${limit}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}




export const v3_edit_warehouse = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/warehouse`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}




export const v3_warehouse_visibility = async (id, visibility) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/warehouse/${id}`;
    return axios.patch(url, { visibility: visibility }, getAuthHeader()).then((res) => {
        return res.data;
    })
}



