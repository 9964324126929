import axios from 'axios'
import { getAuthHeader } from '../../utils';

export const v2_users = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/user`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}



export const v2_user_count = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/count/user`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}