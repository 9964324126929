import React from 'react'
import Logout from '../../components/auth/Logout'
const index = () => {
    return (

        <React.Fragment>
            <Logout />
        </React.Fragment>
    )
}

export default index