import axios from "axios";
import { getAuthHeader } from "../../utils";

export const v3_modules = async (page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/module/${page}/${limit}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const v3_module_assign = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/module-role`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_mapped_roles = async (moduleId) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/mapped-roles/${moduleId}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

