import React, { useEffect } from 'react'
import Layout from '../../components/common/pageLayout'
import Form from '../../components/Gate/add'
import constant from '../../constant'
import { toast } from 'react-toastify'
import { v3_add_gate } from '../../actions/v3/gateManagement'

const Add = (props) => {

    const onSubmit = (model) => {
        v3_add_gate(model).then((res) => {
            if (res.status) {
                toast.success(res.result.message)
                props.history.push(constant.Authedcomponent.listGate.url)
            }
            else {
                toast.error(res.error)

            }
        })
    }
    const onCancel = () => {
        props.history.push(constant.Authedcomponent.listGate.url);
    }

   
    return (
        <React.Fragment>
            <Layout
                title={'Add Gate'}
            >
                <Form
                    title={'Add Gate'}
                    onSubmit={onSubmit}
                    buttonName={'Create Gate'}
                    onCancel={onCancel}

                />
            </Layout>
        </React.Fragment>
    )
}

export default Add