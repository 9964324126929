import React, { useEffect, useState } from 'react'
import Layout from '../../components/common/pageLayout'
import { toast } from 'react-toastify'
import AssignList from '../../components/Voucher/assignList'
import constant from '../../constant'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { v3_assign_voucher, v3_assignable_vouchers } from '../../actions/v3/voucherManagement'
const List = (props) => {

    const location = useLocation();
    const { page } = location.state || {};
    const [data, setData] = useState(null)
    const [currentPage, setCurrentPage] = useState(page ? page : 1)
    const [limit, setLimit] = useState(10)

    useEffect(() => {
        v3_assignable_vouchers(currentPage, limit).then((res) => {
            if (res.status) {
                setData(res.result);
                props.history.push(constant.Authedcomponent.assignVoucher.url, {
                    page: currentPage // Replace 'yourData' with the data you want to pass
                });
            }
            else {
                toast.error(res.error)
            }
        })
    }, [currentPage, limit])

    const onChangeAssignee = (record, newAssignee) => {
        v3_assign_voucher(record.ID, record.INVOICE_NUMBER, JSON.stringify(newAssignee)).then((res) => {
            if (res.status) {
                // Update the state with the new LOGIN_STATUS
                setData(prevState => ({
                    ...prevState, // Preserve other parts of the state
                    vouchers: prevState.vouchers.map(item => {
                        if (item.ID === record.ID) {
                            return {
                                ...item,
                                ASSIGNEE: newAssignee.USER_ID // Update the LOGIN_STATUS
                            };
                        }
                        return item;
                    })
                }));

                // Display a success message
                toast.success(res.result.message);
            }
        });
    };

    return (
        <Layout
            title={'Assign Voucher'}
        >
            {data && <AssignList
                onChangeAssignee={onChangeAssignee}
                limit={limit}
                data={data}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />}
        </Layout>
    )
}

export default List