import React from 'react'
import Layout from '../../components/common/pageLayout'
import Form from '../../components/Product/add'
import constant from '../../constant'
import { toast } from 'react-toastify'
import { v3_add_gate } from '../../actions/v3/gateManagement'
import { v3_add_product } from '../../actions/v3/productManagement'
const Add = (props) => {

    const onSubmit = (model) => {
        v3_add_product(model).then((res) => {
            if (res.status) {
                toast.success(res.result.message)
                // props.history.push(constant.Authedcomponent.listProduct.url)
            }
            else {
                toast.error(res.error)

            }
        })
    }
    const onCancel = () => {
        props.history.push(constant.Authedcomponent.listProduct.url);
    }
    return (
        <React.Fragment>
            <Layout
                title={'Add Product'}
            >
                <Form
                    title={'Add Product'}
                    onSubmit={onSubmit}
                    buttonName={'Create Product'}
                    onCancel={onCancel}

                />
            </Layout>
        </React.Fragment>
    )
}

export default Add