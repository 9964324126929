import React from 'react'
import { Link, withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import Paginations from '../common/paginations';
import constant from '../../constant';

const List = ({ data, setCurrentPage, limit, onChangeVisibility }) => {

    const { company, total, page, totalPages } = data;
    return (
        <React.Fragment>
            <div className="table-responsive">
                <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                        <tr>
                            <th scope="col" style={{ width: 70 }}>
                                #
                            </th>
                            <th scope="col">Company Name</th>
                            <th scope="col">Contact Details</th>
                            <th scope="col">Other Information</th>

                            <th scope="col">Modified By </th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {company?.map((item, index) => {
                            return (
                                <tr>
                                    <td>
                                        <div className="avatar-xs">
                                            <span className="avatar-title rounded-circle">{item.COMPANY_NAME.charAt(0)}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 className="font-size-14 mb-1">
                                            <a href="javascript:void(0);" className="text-dark">
                                                {item.COMPANY_NAME}
                                            </a>
                                        </h5>
                                        <p className="text-muted mb-0">
                                            <span className={`badge border border-light rounded-circle ${item.STATUS === 'A' ? 'bg-success' : 'bg-warning'} p-1`} >
                                                <span className="visually-hidden" />
                                            </span>
                                        </p>
                                    </td>
                                    <td>
                                        <h5 className="font-size-14">
                                            {item.CONTACT_PERSON}
                                        </h5>
                                        <p className="text-muted mb-0">{item.EMAIL}</p>
                                        <p className="text-muted mb-0">{item.PHONE}</p>
                                    </td>
                                    <td><button
                                        type="button"
                                        data-bs-toggle="modal"
                                        data-bs-target={`#staticBackdrop${index}`}
                                        className="btn btn-success waves-effect btn-label waves-light"
                                    >
                                        <i className="bx bx-street-view label-icon " /> View Details{" "}
                                    </button>

                                        {/* STATIC BACKDROP MODAL */}
                                        <div
                                            className="modal fade"
                                            id={`staticBackdrop${index}`}
                                            data-bs-backdrop="static"
                                            data-bs-keyboard="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-labelledby="staticBackdropLabel"
                                            aria-hidden="true"
                                        >
                                            <div
                                                className="modal-dialog modal-dialog-centered modal-lg"
                                                role="document"
                                            >
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="staticBackdropLabel">
                                                            {item.COMPANY_NAME} - Information
                                                        </h5>
                                                        <button
                                                            type="button"
                                                            className="btn-close"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                        />
                                                    </div>
                                                    <div className="modal-body">
                                                        <p></p>
                                                        <table className="table table-striped mb-0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Company Name</td>
                                                                    <td>{item.COMPANY_NAME}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Company Type</td>
                                                                    <td>{item.COMPANY_TYPE_NAME}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Business Category</td>
                                                                    <td>{item.BUSINESS_CATEGORY_NAME}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Industry</td>
                                                                    <td>{item.INDUSTRY_NAME}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Company Detail</td>
                                                                    <td>{item.COMPANY_DETAIL}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Contact Person</td>
                                                                    <td>{item.CONTACT_PERSON}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Email</td>
                                                                    <td>{item.EMAIL}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Phone</td>
                                                                    <td>{item.PHONE}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Address</td>
                                                                    <td>{item.ADDRESS}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>State</td>
                                                                    <td>{item.STATE}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>District</td>
                                                                    <td>{item.DISTRICT}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>GST Number</td>
                                                                    <td>{item.GST_NUMBER}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Pan Number</td>
                                                                    <td>{item.PAN_NUMBER}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Modified By</td>
                                                                    <td>{item.MODIFIED_BY_NAME}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Modified On</td>
                                                                    <td>{item.MODIFIED_ON}</td>
                                                                </tr>
                                                            </tbody>


                                                        </table>
                                                        <p />
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button
                                                            type="button"
                                                            className="btn btn-light"
                                                            data-bs-dismiss="modal"
                                                        >
                                                            Close
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* MODEL CLOSED */}

                                    </td>

                                    <td>{item.MODIFIED_BY_NAME}</td>

                                    <td>
                                        <ul className="list-inline font-size-20 contact-links mb-0">
                                            <li className="list-inline-item px-2">
                                                <Link to={{
                                                    pathname: constant.Authedcomponent.editCompany.url,
                                                    state: {
                                                        data: btoa(JSON.stringify(item)),
                                                        page: page
                                                    }  // Passing the entire `item` object as data
                                                }} title="Edit Company Type">
                                                    <i className="bx bx-edit" />
                                                </Link>
                                            </li>
                                            <li className="list-inline-item px-2">
                                                <a
                                                    href="javascript:void(0);"
                                                    style={{ color: "red" }}
                                                    title="Delete User"
                                                    onClick={() => { onChangeVisibility(item) }}
                                                >
                                                    <i className={`bx ${item.STATUS === 'A' ? 'bx-check-circle' : 'bx-error-circle'}`} />

                                                </a>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            </div>
            <Paginations
                limit={limit}
                totalCount={total}
                range={5}
                currentPage={page}
                onPageChange={setCurrentPage}
            />
        </React.Fragment>


    )
}

export default withRouter(List)