import React from 'react'
import Layout from '../../../components/common/pageLayout'
import Form from '../../../components/MasterData/State/add'
import { v3_add_state } from '../../../actions/v3/masterData'
import constant from '../../../constant'
import { toast } from 'react-toastify'
const Add = (props) => {

    const onSubmit = (model) => {
        v3_add_state(model).then((res) => {
            if (res.status) {
                toast.success(res.result.message)
                props.history.push(constant.Authedcomponent.listStateMasterData.url)
            }
            else {
                toast.error(res.error)

            }
        })
    }
    return (
        <React.Fragment>
            <Layout
                title={'Add State'}
            >
                <Form
                    title={'Add State'}
                    onSubmit={onSubmit}
                    buttonName={'Create State'}
                />
            </Layout>
        </React.Fragment>
    )
}

export default Add