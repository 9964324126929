import React, { useEffect, useState } from 'react'
import { getStorage } from '../../utils'
import constant from '../../constant'
import StocksGraph from '../../components/Dashboard/StocksGraph'
import moment from 'moment'
import CountCards from '../../components/Dashboard/CountCards'
import { v2_warehouse_count } from '../../actions/v2/warehouse'
import { v2_company_count } from '../../actions/v2/company'
import { v2_voucher_count, v2_voucher_monthly_ratio } from '../../actions/v2/voucher'
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { v2_transporter_count } from '../../actions/v2/transporter'
import { v2_user_count } from '../../actions/v2/user'
import { v2_product_count } from '../../actions/v2/product'
import AssignList from '../../components/Voucher/assignList'
import { v3_assign_voucher, v3_assignable_vouchers } from '../../actions/v3/voucherManagement'
import { toast } from 'react-toastify'
const Index = (props) => {
  const location = useLocation();

  const [ud, set_ud] = useState(JSON.parse(atob(getStorage(constant.keys.userDetails))))
  const [count, setCount] = useState({ voucher: '', company: '', warehouse: '', user: '', transporter: '', product: '' })
  const [voucherRatio, setVoucherRatio] = useState({ increaseRatefromlastmonth: '', lastMonthCount: '', thisMonthCount: '' })

  useEffect(() => {
    v2_voucher_count().then((res) => {
      if (res.status) {
        setCount((prevCount) => ({
          ...prevCount,
          voucher: res.result
        }));
      }
    });

    v2_company_count().then((res) => {
      if (res.status) {
        setCount((prevCount) => ({
          ...prevCount,
          company: res.result
        }));
      }
    });

    v2_warehouse_count().then((res) => {
      if (res.status) {
        setCount((prevCount) => ({
          ...prevCount,
          warehouse: res.result
        }));
      }
    });

    v2_transporter_count().then((res) => {
      if (res.status) {
        setCount((prevCount) => ({
          ...prevCount,
          transporter: res.result
        }));
      }
    });
    v2_user_count().then((res) => {
      if (res.status) {
        setCount((prevCount) => ({
          ...prevCount,
          user: res.result
        }));
      }
    });
    v2_product_count().then((res) => {
      if (res.status) {
        setCount((prevCount) => ({
          ...prevCount,
          product: res.result
        }));
      }
    });

    v2_voucher_monthly_ratio().then((res) => {
      if (res.status) {
        setVoucherRatio(res.result);
      }
    });
  }, [])

  const { page } = location.state || {};
  const [data, setData] = useState(null)
  const [currentPage, setCurrentPage] = useState(page ? page : 1)
  const [limit, setLimit] = useState(5)

  useEffect(() => {
    v3_assignable_vouchers(currentPage, limit).then((res) => {
      if (res.status) {
        setData(res.result);
        props.history.push(constant.Authedcomponent.dashboard.url, {
          page: currentPage // Replace 'yourData' with the data you want to pass
        });
      }
      else {
        toast.error(res.error)
      }
    })
  }, [currentPage, limit])

  const onChangeAssignee = (record, newAssignee) => {
    v3_assign_voucher(record.ID, record.INVOICE_NUMBER, JSON.stringify(newAssignee)).then((res) => {
      if (res.status) {
        // Update the state with the new LOGIN_STATUS
        setData(prevState => ({
          ...prevState, // Preserve other parts of the state
          vouchers: prevState.vouchers.map(item => {
            if (item.ID === record.ID) {
              return {
                ...item,
                ASSIGNEE: newAssignee.USER_ID // Update the LOGIN_STATUS
              };
            }
            return item;
          })
        }));

        // Display a success message
        toast.success(res.result.message);
      }
    });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 font-size-18">
                  Eco Green Administrator Control Panel V1.0{" "}
                </h4>
                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <a href="">Home</a>
                    </li>
                    <li className="breadcrumb-item active">&gt;&gt; Dashboard</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* end page title */}
          <div className="row">
            <div className="col-xl-4">
              <div className="card overflow-hidden">
                <div className="bg-success bg-soft">
                  <div className="row">
                    <div className="col-7">
                      <div className="text-black p-3">
                        <h5 className="text-black">Welcome Back !</h5>
                        <p>Eco Green Dashboard</p>
                      </div>
                    </div>
                    <div className="col-5 align-self-end">
                      <img
                        src="assets/images/profile-img.png"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="avatar-md profile-user-wid mb-4">
                        <img
                          src="assets/images/users/avatar-2.jpg"
                          alt=""
                          className="img-thumbnail rounded-circle"
                        />
                      </div>
                      <h5 className="font-size-15 text-truncate">{ud.fullName}</h5>
                      <p className="text-muted mb-0 text-truncate">Administrator</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Monthly Voucher</h4>
                  <div className="row">
                    <div className="col-sm-12">
                      <p className="text-muted">This month</p>
                      <h3>{voucherRatio.thisMonthCount}</h3>
                      <p className="text-muted">
                        <span
                          className={`me-2 ${voucherRatio.increaseRatefromlastmonth >= 0 ? 'text-success' : 'text-danger'}`}
                        >
                          {voucherRatio.increaseRatefromlastmonth >= 0 ? (
                            <>
                              {voucherRatio.increaseRatefromlastmonth}% <i className="mdi mdi-arrow-up" />
                            </>
                          ) : (
                            <>
                              {Math.abs(voucherRatio.increaseRatefromlastmonth)}% <i className="mdi mdi-arrow-down" />
                            </>
                          )}
                        </span>{" "}
                        From previous period
                      </p>

                      <div className="mt-4">
                        <Link
                          to={constant.Authedcomponent.listVoucher.url}
                          className="btn btn-success waves-effect waves-light btn-sm"
                        >
                          View More <i className="mdi mdi-arrow-right ms-1" />
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="mt-4 mt-sm-0">
                        <div id="radialBar-chart" className="apex-charts" />
                      </div>
                    </div>
                  </div>
                  <p className="text-muted mb-0">
                    Manage vocuher, company, warehouse &amp; vehicle etc.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-8">
              <CountCards
                count={count}
              />
              {/* end row */}
              <div className="card">
                <div className="card-body">
                  <StocksGraph periodStartMonth={moment().subtract(6, 'months').format('MMM YYYY')} periodEndMonth={moment().format('MMM YYYY')} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card" style={{ marginLeft: 10 }}>
                <div className="card-body">
                  <h4 className="card-title mb-4">Latest Voucher's</h4>
                  {data && <AssignList
                    onChangeAssignee={onChangeAssignee}
                    limit={limit}
                    data={{ ...data, total: 0 }}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />}
                  <div className="row">
                    <div className="col-lg-12">
                      <Link
                        className="btn btn-primary waves-effect btn-label waves-light"
                        to={constant.Authedcomponent.listVoucher.url}
                      >
                        <i className="bx bxs-right-arrow-square label-icon" /> View All Voucher's
                      </Link>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* End Page-content */}
    </React.Fragment>

  )
}

export default Index