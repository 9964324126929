import axios from "axios";
import { getAuthHeader } from "../../utils";

export const v3_add_product = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/product`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_products = async (page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/product/${page}/${limit}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_edit_product = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/product`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}




export const v3_product_visibility = async (id, visibility) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/product/${id}`;
    return axios.patch(url, { visibility: visibility }, getAuthHeader()).then((res) => {
        return res.data;
    })
}



export const v3_add_sub_product = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/sub-product`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_sub_products = async (page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/sub-product/${page}/${limit}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_edit_sub_product = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/sub-product`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}




export const v3_sub_product_visibility = async (id, visibility) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/sub-product/${id}`;
    return axios.patch(url, { visibility: visibility }, getAuthHeader()).then((res) => {
        return res.data;
    })
}



