import React from 'react'
import Layout from '../../components/auth/layout'
import LoginForm from '../../components/auth/login'
import { v1_login } from '../../actions/v1/auth'
import { setStorage } from '../../utils'
import constant from '../../constant'
import swal from 'sweetalert';

const Login = (props) => {
    const onLogin = (model) => {
        v1_login(model).then(res => {
            if (res.status) {
                setStorage(constant.keys.userDetails, res.result.ud);
                setStorage(constant.keys.userToken, res.result.token);
                props.history.push(constant.Authedcomponent.dashboard.url)
            }
            else {
                swal({
                    title: res.error.message,
                    icon: "warning",
                    dangerMode: true,
                })
            }
        })
    }
    return (
        <React.Fragment>
            <Layout>
                <LoginForm
                    onSubmit={onLogin}
                />
            </Layout>
        </React.Fragment>)
}

export default Login