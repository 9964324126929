import React from 'react'
import { Link, withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import Paginations from '../../common/paginations';
import constant from '../../../constant';
import moment from 'moment';

const List = ({ data, setCurrentPage, limit, userName }) => {

    const { logs, total, page, totalPages } = data;
    return (
        <React.Fragment>


            <div className="table-responsive">
                <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                        <tr>
                            <th scope="col" style={{ width: 70 }}>
                                #
                            </th>
                            <th scope="col">Activity Name</th>
                            <th scope="col">Activity Details</th>
                            <th scope="col">Activity Time </th>
                        </tr>
                    </thead>
                    <tbody>
                        {logs?.map((item, index) => {
                            return (
                                <tr>
                                    <td>
                                        <div className="avatar-xs">
                                            <span className="avatar-title rounded-circle">{(page - 1) * limit + index + 1}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 className="font-size-14 mb-1">
                                            <a href="javascript:void(0);" className="text-dark">
                                                {item.ACTIVITY_NAME}
                                            </a>
                                        </h5>
                                    </td>
                                    <td>{userName} {item.ACTIVITY_DETAIL}</td>
                                    <td>{moment(item.CREATED_ON).format('DD MM YYYY on hh:mm A')}</td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            </div>
            <Paginations
                limit={limit}
                totalCount={total}
                range={5}
                currentPage={page}
                onPageChange={setCurrentPage}
            />
        </React.Fragment>


    )
}

export default withRouter(List)