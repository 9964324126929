import React from 'react'

const CountCards = ({ count }) => {

    return (
        <div className="row">
        {[
            { label: 'Vouchers', value: count.voucher, iconClass: 'bx bxs-receipt' },
            { label: 'Company', value: count.company, iconClass: 'bx bxs-buildings' },
            { label: 'Transporter', value: count.transporter, iconClass: 'bx bxs-truck' },
            { label: 'User', value: count.user, iconClass: 'bx bxs-user-circle' },
            { label: 'Warehouse', value: count.warehouse, iconClass: 'bx bxs-store' },
            { label: 'Product / Item', value: count.product, iconClass: 'bx bxs-box' }

        ].map((item, index) => (
            <div key={index} className="col-md-4">
                <div className="card mini-stats-wid">
                    <div className="card-body">
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p className="text-muted fw-medium">{item.label}</p>
                                <h4 className="mb-0">{item.value}</h4>
                            </div>
                            <div className="flex-shrink-0 align-self-center">
                                <div className="avatar-sm rounded-circle bg-success mini-stat-icon">
                                    <span className="avatar-title rounded-circle bg-success">
                                        <i className={`${item.iconClass} font-size-24`} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))}
    </div>
    

    )
}

export default CountCards