import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import constant from '../../../constant';

const AssignModule = (props) => {
    const { modules, page } = props.data;
    return (
        <React.Fragment>
            <div className="table-responsive">
                <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                        <tr>
                            <th scope="col" style={{ width: 70 }}>
                                #
                            </th>
                            <th scope="col">Module Name</th>
                            <th scope="col">Module Details</th>
                            <th scope="col">Assign To Roles</th>

                        </tr>
                    </thead>
                    <tbody>
                        {modules?.map((item, index) => {
                            return (
                                <tr>
                                    <td>
                                        <div className="avatar-xs">
                                            <span className="avatar-title rounded-circle">{item.MODULE_NAME.charAt(0)}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 className="font-size-14 mb-1">
                                            <a href="javascript:void(0);" className="text-dark">
                                                {item.MODULE_NAME}
                                            </a>
                                        </h5>
                                        <p className="text-muted mb-0">
                                            {'Visibility'}{" "}
                                            <span className={`badge border border-light rounded-circle ${item.STATUS === 'A' ? 'bg-success' : 'bg-warning'} p-1`} >
                                                <span className="visually-hidden" />
                                            </span>
                                        </p>
                                    </td>
                                    <td>{item.MODULE_DETAIL}</td>
                                    <td>
                                        <ul className="list-inline font-size-20 contact-links mb-0">
                                            <li className="list-inline-item px-2">
                                                <Link to={{
                                                    pathname: constant.Authedcomponent.assignModules.url,
                                                    state: {
                                                        moduleData: btoa(JSON.stringify(item)),
                                                        page: page
                                                    }  // Passing the entire `item` object as data
                                                }} title="Assign Module">
                                                    <i className="fas fa-user-shield" />
                                                </Link>
                                            </li>

                                        </ul>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </React.Fragment>
    )
}

export default AssignModule