import React, { useEffect, useState } from 'react';
import Layout from '../../../components/common/pageLayout';
import { toast } from 'react-toastify';
import ListRole from '../../../components/Administration/module/assign';
import { v3_roles } from '../../../actions/v3/role';
import constant from '../../../constant';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { v3_mapped_roles, v3_module_assign } from '../../../actions/v3/module';

const List = (props) => {
    const location = useLocation();
    const { page, moduleData } = location.state || {};
    const module = JSON.parse(atob(moduleData))

    const [data, setData] = useState(null);
    const [currentPage, setCurrentPage] = useState(page || 1);
    const [limit, setLimit] = useState(10);


    const [previouslyMappedRoles, setPreviouslyMappedRoles] = useState([])
    useEffect(() => {
        v3_mapped_roles(module.MODULE_ID).then((res) => {
            if (res.status) {
                setPreviouslyMappedRoles(res.result)
            }
        })
    }, [])



    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const res = await v3_roles(currentPage, limit);
                if (res.status) {
                    setData(res.result);
                    // props.history.push(constant.Authedcomponent.assignModules.url, {
                    //     page: currentPage,
                    // });
                } else {
                    toast.error(res.error);
                }
            } catch (error) {
                toast.error('Failed to fetch roles.');
            }
        };

        fetchRoles();
    }, [currentPage, limit, props.history]);


    const onSave = (roles) => {
        v3_module_assign({ roles, moduleId: module.MODULE_ID, moduleName: module.MODULE_NAME }).then((res) => {
            if (res.status) {
                toast.success(res.result.message)
                props.history.push(constant.Authedcomponent.listModules.url, {
                    page: page // Replace 'yourData' with the data you want to pass
                });
            }
            else {
                toast.error(res.error)
            }
        })
    }

    return (
        <Layout title={'Assign Module'}>
            {data && (
                <ListRole
                    onSave={onSave}
                    previouslyMappedRoles={previouslyMappedRoles}
                    data={data}
                    limit={limit}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                />
            )}
        </Layout>
    );
};

export default List;
