import axios from 'axios'
import { getAuthHeader } from '../../utils';

export const v3_add_role = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/role`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_roles = async (page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/role/${page}/${limit}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_role_visibility = async (roleId, visibility) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/role/${roleId}`;
    return axios.patch(url, { visibility: visibility }, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_edit_role = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/role`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}