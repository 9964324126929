import React from 'react'
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import Paginations from '../common/paginations';
import moment from 'moment';

const List = ({ data, setCurrentPage, limit }) => {
    const { vouchers, total, page, totalPages } = data;
    console.log(vouchers)

    return (
        <React.Fragment>
            <div className="table-responsive">
                <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                        <tr>
                            <th scope="col" style={{ width: 70 }}>
                                Voucher Id
                            </th>
                            <th scope="col">Voucher Details</th>
                            <th scope="col">Created By</th>
                            <th scope="col">Modified By</th>
                            <th scope="col">Other Information</th>
                        </tr>
                    </thead>
                    <tbody>
                        {vouchers?.length > 0 ? (
                            vouchers.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <div className="avatar-xs">
                                            <span className="avatar-title rounded-circle">{item.ID}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 className="font-size-14">{item.INVOICE_NUMBER}</h5>
                                        <p className="text-muted mb-0">{item.SUB_PRODUCT_NAME} ({item.PRODUCT_NAME})</p>
                                        <p className="text-muted mb-0">{item.CHALLAN_WEIGHT}</p>
                                    </td>
                                    <td>
                                        {item.CREATED_BY_NAME}
                                        <p>{moment(item.CREATED_ON).format('DD MMM, YYYY on hh:mm A')}</p>
                                    </td>
                                    <td>
                                        {item.MODIFIED_BY_NAME}
                                        <p>{moment(item.MODIFIED_ON).format('DD MMM, YYYY on hh:mm A')}</p>
                                    </td>
                                    <td>
                                        <button
                                            type="button"
                                            data-bs-toggle="modal"
                                            data-bs-target={`#staticBackdrop${index}`}
                                            className="btn btn-success waves-effect btn-label waves-light"
                                        >
                                            <i className="bx bx-street-view label-icon" /> View Details
                                        </button>
                                        {/* STATIC BACKDROP MODAL */}
                                        <div
                                            className="modal fade"
                                            id={`staticBackdrop${index}`}
                                            data-bs-backdrop="static"
                                            data-bs-keyboard="false"
                                            tabIndex={-1}
                                            role="dialog"
                                            aria-labelledby="staticBackdropLabel"
                                            aria-hidden="true"
                                        >
                                            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title" id="staticBackdropLabel">
                                                            {item.INVOICE_NUMBER} - Information
                                                        </h5>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                    </div>
                                                    <div className="modal-body">
                                                        <table className="table table-striped mb-0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Date</td>
                                                                    <td>{item.DATE ? moment(item.DATE).format('DD MMM, YYYY') : 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Voucher</td>
                                                                    <td>{item.VOUCHER_TYPE || 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Invoice No</td>
                                                                    <td>{item.INVOICE_NUMBER || 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Particular (Item)</td>
                                                                    <td>{item.SUB_PRODUCT_NAME ? `${item.SUB_PRODUCT_NAME} (${item.PRODUCT_NAME})` : 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Challan Weight</td>
                                                                    <td>{item.CHALLAN_WEIGHT || 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Party Name</td>
                                                                    <td>{item.PARTY_NAME || 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Mill</td>
                                                                    <td>{item.MILL || 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Actual Weight</td>
                                                                    <td>{item.ACTUAL_WEIGHT || 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Actual Rate</td>
                                                                    <td>{item.ACTUAL_RATE || 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Invoice Rate</td>
                                                                    <td>{item.INVOICE_RATE || 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Amount</td>
                                                                    <td>{item.AMOUNT || 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>GST</td>
                                                                    <td>{item.GST || 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Grand Total</td>
                                                                    <td>{item.GRAND_TOTAL || 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Source</td>
                                                                    <td>{item.SOURCE || 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Vehicle Details</td>
                                                                    <td>{item.VEHICLE_NAME} ({item.VEHICLE_NUMBER || 'N/A'})</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Weight Loss</td>
                                                                    <td>{item.WEIGHT_LOSS || 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Payment Date</td>
                                                                    <td>{item.PAYMENT_DATE ? moment(item.PAYMENT_DATE).format('DD MMM, YYYY') : 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>90% Payment</td>
                                                                    <td>{item.PAYMENT_AMOUNT || 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Debit Note</td>
                                                                    <td>{item.DEBIT_NOTE || 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Debit Note GST</td>
                                                                    <td>{item.DEBIT_NOTE_GST || 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>GST Pending</td>
                                                                    <td>{item.GST_PENDING || 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Balance Pending</td>
                                                                    <td>{item.BALANCE_PENDING || 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Net Balance Pending</td>
                                                                    <td>{item.NET_BALANCE_PENDING || 'N/A'}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>TDS</td>
                                                                    <td>{item.TDS || 'N/A'}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <p />
                                                    </div>
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">
                                                            Close
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* MODAL END */}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td className='text-danger text-center' colSpan={6}>
                                    No records found. Please apply filters to get the correct records.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </div>
            <Paginations
                limit={limit}
                totalCount={total}
                range={5}
                currentPage={page}
                onPageChange={setCurrentPage}
            />
        </React.Fragment>


    )
}

export default withRouter(List)