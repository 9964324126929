import React, { useEffect, useState } from 'react'
import Paginations from '../../common/paginations';

const AssignModule = (props) => {
    const { data, previouslyMappedRoles, limit, setCurrentPage, onSave } = props;
    const { roles, page, total } = data

    const [selectedRoles, setSelectedRoles] = useState([]);

    useEffect(() => {
        // Set selectedRoles from previouslyMappedRoles on component mount
        if (previouslyMappedRoles) {
            const mappedRoleIds = previouslyMappedRoles.map(role => role.ROLE_ID);
            setSelectedRoles(mappedRoleIds);
        }
    }, [previouslyMappedRoles]);

    const onChange = (role) => {
        const { ROLE_ID } = role;

        // Check if the role is already selected
        if (selectedRoles.includes(ROLE_ID)) {
            // If the role is already selected, remove it from the array
            setSelectedRoles(selectedRoles.filter(id => id !== ROLE_ID));
        } else {
            // If the role is not selected, add it to the array
            setSelectedRoles([...selectedRoles, ROLE_ID]);
        }
    };


    const handleSave = () => {
        // Call the onSave prop function to save the selected roles
        if (onSave) {
            onSave(selectedRoles);
        } else {
            console.log('Selected roles:', selectedRoles);
            // You can replace this with actual saving logic
        }
    };


    return (
        <React.Fragment>
            <div className="table-responsive">
                <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                        <tr>
                            <th scope="col" style={{ width: 70 }}>
                                <input
                                    type="checkbox"
                                    onClick={() => {
                                        const allRoleIds = roles.map(role => role.ROLE_ID);
                                        if (allRoleIds.every(id => selectedRoles.includes(id))) {
                                            // If all roles are selected, deselect all
                                            setSelectedRoles([]);
                                        } else {
                                            // Otherwise, select all roles
                                            setSelectedRoles(allRoleIds);
                                        }
                                    }}
                                    checked={roles.every(role => selectedRoles.includes(role.ROLE_ID))}
                                />
                            </th>
                            <th scope="col">Role Name</th>
                            <th scope="col">Role Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {roles?.map((item, index) => {
                            return (
                                <tr>
                                    <td>
                                        <input
                                            type="checkbox"
                                            onClick={() => onChange(item)}
                                            checked={selectedRoles.includes(item.ROLE_ID)}
                                        />

                                    </td>

                                    <td>
                                        <h5 className="font-size-14 mb-1">
                                            {item.ROLE_NAME}
                                            {" "} <span className={`badge border border-light rounded-circle ${item.STATUS === 'A' ? 'bg-success' : 'bg-warning'} p-1`} >
                                                <span className="visually-hidden" />
                                            </span>
                                        </h5>

                                    </td>
                                    <td>{item.ROLE_DETAIL}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <Paginations
                    limit={limit}
                    totalCount={total}
                    range={5}
                    currentPage={page}
                    onPageChange={setCurrentPage}
                />
                <button className="btn btn-success" onClick={handleSave}>
                    Save
                </button>
            </div>

        </React.Fragment>
    )
}

export default AssignModule