import React, { useEffect, useState } from 'react'
import Layout from '../../components/common/pageLayout'
import { toast } from 'react-toastify'
import ListStock from '../../components/Stock/list'
import constant from '../../constant'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { v3_stocks } from '../../actions/v3/stockManagement'
const List = (props) => {

    const location = useLocation();
    const { page } = location.state || {};
    const [data, setData] = useState(null)
    const [currentPage, setCurrentPage] = useState(page ? page : 1)
    const [limit, setLimit] = useState(10)

    useEffect(() => {
        v3_stocks(currentPage, limit).then((res) => {
            if (res.status) {
                setData(res.result);
                props.history.push(constant.Authedcomponent.listStock.url, {
                    page: currentPage // Replace 'yourData' with the data you want to pass
                });
            }
            else {
                toast.error(res.error)
            }
        })
    }, [currentPage, limit])

    

    return (
        <Layout
            title={'Stock List'}
        >
            {data && <ListStock
                limit={limit}
                data={data}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />}
        </Layout>
    )
}

export default List