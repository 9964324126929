import axios from 'axios'
import { getAuthHeader } from '../../utils';

export const v3_add_transporter = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/transporter`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_transporters = async (page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/transporter/${page}/${limit}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_transporter_visibility = async (transporterId, visibility) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/transporter/${transporterId}`;
    return axios.patch(url, { visibility: visibility }, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_edit_transporter = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/transporter`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_add_transporter_vehicle = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/transporter/vehicle`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_transporter_vehicles = async (page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/transporter/vehicle/${page}/${limit}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_transporter_vehicle_visibility = async (transporterId, visibility) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/transporter/vehicle/${transporterId}`;
    return axios.patch(url, { visibility: visibility }, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_edit_transporter_vehicle = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/transporter/vehicle`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}