import axios from 'axios'
import { getAuthHeader } from '../../utils';

export const v2_voucher_count = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/count/voucher`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
export const v2_voucher_monthly_ratio = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/count/voucher/monthly-ratio`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v2_voucher_report = async (model, page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/report/voucher/${page}/${limit}`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}
