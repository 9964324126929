import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { v2_stock_graph_data } from '../../actions/v2/stock';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';

// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

const StocksGraph = ({ periodStartMonth, periodEndMonth }) => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await v2_stock_graph_data(periodStartMonth, periodEndMonth);
                if (data.status) {
                    prepareChartData(data.result);
                }
            } catch (error) {
                console.error('Error fetching stock graph data:', error);
            }
        };

        fetchData();
    }, [periodStartMonth, periodEndMonth]);

    const prepareChartData = (data) => {
        // Extracting the labels (months) from the data
        const labels = data.map(item => item.month);

        // Extracting the category names from the first entry in the data
        const categories = Object.keys(data[0].categories);

        // Creating datasets for each category
        const datasets = categories.map((categoryName, index) => ({
            label: categoryName, // Use category name directly as the label
            data: data.map(item => item.categories[categoryName] || 0),
            fill: false,
            borderColor: `hsl(${index * 60}, 70%, 50%)`, // Dynamic color code for each line
            tension: 0.1, // Smooth line curve
        }));

        // Setting the chart data
        setChartData({
            labels,
            datasets,
        });
    };

    const chartOptions = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Month',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Stock Count',
                },
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: (context) => {
                        return `${context.dataset.label}: ${context.raw}`;
                    },
                },
            },
        },
    };

    if (!chartData) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h2>Stock Data Over Time</h2>
            <Line data={chartData} options={chartOptions} />
        </div>
    );
};

export default StocksGraph;
