import React from 'react'
import Layout from '../../../components/common/pageLayout'
import Form from '../../../components/Transporter/Vehicle/add'
import constant from '../../../constant'
import { toast } from 'react-toastify'
import { v3_add_transporter_vehicle } from '../../../actions/v3/trasnporterManagement'
const Add = (props) => {

    const onSubmit = (model) => {
        v3_add_transporter_vehicle(model).then((res) => {
            if (res.status) {
                toast.success(res.result.message)
                props.history.push(constant.Authedcomponent.listTransporterVehicle.url)
            }
            else {
                toast.error(res.error)

            }
        })
    }
    const onCancel = () => {
        props.history.push(constant.Authedcomponent.listTransporterVehicle.url);
    }
    return (
        <React.Fragment>
            <Layout
                title={'Add Vehicle'}
            >
                <Form
                    title={'Add Vehicle'}
                    onSubmit={onSubmit}
                    buttonName={'Create Vehicle'}
                    onCancel={onCancel}
                />
            </Layout>
        </React.Fragment>
    )
}

export default Add