import axios from 'axios'
import { getAuthHeader } from '../../utils';

export const v2_transporters = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/transporter`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v2_transporter_vehicles = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/transporter/vehicle/${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v2_transporter_count = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/count/transporter`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
