import constant from "./constant";

export const removeStorage = (keyName) => {
  localStorage.removeItem(keyName);
}

export const setStorage = (key, jsonObj) => {
  localStorage.setItem(key, JSON.stringify(jsonObj))
}


export const getStorage = (keyName) => {
  const item = localStorage.getItem(keyName);
  return JSON.parse(item);
}

export const removeAllLocalStorage = () => {
  localStorage.clear();
}

export const removeSessionStorage = (keyName) => {
  sessionStorage.removeItem(keyName);
}

export const setSessionStorage = (key, jsonObj) => {
  sessionStorage.setItem(key, JSON.stringify(jsonObj));
}

export const getSessionStorage = (keyName) => {
  const item = sessionStorage.getItem(keyName);
  return JSON.parse(item);
}

export const removeAllSessionStorage = () => {
  sessionStorage.clear();
}


export const validateFunctionalForm = (state, setState) => {
  const updatedState = { ...state };
  let status = true;

  Object.keys(updatedState).forEach(key => {
    const field = updatedState[key];

    if (field.isRequired) {
      const value = field?.value;

      if (value === undefined || value === null) {
        updatedState[key] = { ...field, error: 'This field is required' };
        status = false;
      } else if (typeof value === 'string' && value.trim() === '') {
        updatedState[key] = { ...field, error: 'This field is required' };
        status = false;
      } else if (Array.isArray(value) && value.length === 0) {
        updatedState[key] = { ...field, error: 'This field is required' };
        status = false;
      } else {
        updatedState[key] = { ...field, error: '' };
      }
    } else {
      updatedState[key] = { ...field, error: '' };
    }
  });

  setState(updatedState);
  return status;
};


export function getGreeting() {
  const currentHour = new Date().getHours();

  if (currentHour >= 5 && currentHour < 12) {
      return "Good morning";
  } else if (currentHour >= 12 && currentHour < 17) {
      return "Good afternoon";
  } else {
      return "Good evening";
  }
}

export const getAuthHeader = () => {
  const token = getStorage(constant.keys.userToken);
  const header = {
    headers: { Authorization: "Bearer " + token }
  };
  return header;
}
