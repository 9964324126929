import React from 'react'
import moment from 'moment'

const footer = () => {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                        Copyright © 2023-{moment().format('YY')}, Eco Green. All Rights Reserved.
                    </div>
                    <div className="col-sm-6">
                        <div className="text-sm-end d-none d-sm-block">
                            Powered
                            by{" "}
                            <a
                                href="https://shashwatco.com/"
                            >
                                Shashwat Web Technologies Pvt Ltd.
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default footer