import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import constant from '../../constant';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Report from '../../components/Voucher/report';
import { v2_voucher_report } from '../../actions/v2/voucher';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { v2_gates } from '../../actions/v2/gate';
import { v2_companies } from '../../actions/v2/company';
import { v2_voucher_type } from '../../actions/v2/masterData';
import { v2_warehouse } from '../../actions/v2/warehouse';
import { validateFunctionalForm } from '../../utils';
import { v1_voucher_report } from '../../actions/v1/voucher';

const List = (props) => {
    // Fields you want to remove from the data
    const fieldsToRemove = [
        'VOUCHER_TYPE_ID', 'PRODUCT_ID', 'SUB_PRODUCT_ID', 'VEHICLE_ID', 'GATE_ID',
        'TRANSPORTER_ID', 'ASSIGNEE', 'MODIFIED_BY_NAME', 'CREATED_BY_NAME',
        'CREATED_ON', 'MODIFIED_ON', 'PARTY_ID', 'MILL_ID', 'SOURCE_ID',
        'WAREHOUSE_ID',
    ];

    // Function to remove unwanted fields from data
    function removeFields(data) {
        return data.map(item => {
            fieldsToRemove.forEach(field => {
                delete item[field]; // Remove each unwanted field
            });
            return item;
        });
    }
    // Function to rename headers before exporting
    function renameHeaders(data) {
        const headerMapping = {
            SOURCE_INVOICE_NUMBER: 'Source Invoice Number',
            PRODUCT_NAME: 'Product Type',
            TRANSPORTER_NAME: 'Transporter',
            WAREHOUSE_NAME: 'Warehouse',
            GATE_NAME: 'Gate',
            INVOICE_NUMBER: 'Invoice No',
            DATE: 'Date',
            VOUCHER_TYPE: 'Voucher',
            SUB_PRODUCT_NAME: 'Particular (Item)',
            CHALLAN_WEIGHT: 'Challan Weight',
            PARTY_NAME: 'Party Name',
            MILL: 'Mill',
            ACTUAL_WEIGHT: 'Actual Weight',
            ACTUAL_RATE: 'Actual Rate',
            INVOICE_RATE: 'Invoice Rate',
            AMOUNT: 'Amount',
            GST: 'GST',
            GRAND_TOTAL: 'Grand Total',
            SOURCE: 'Source',
            VEHICLE_NAME: 'Vehicle Name',
            VEHICLE_NUMBER: 'Vehicle Number',
            WEIGHT_LOSS: 'Weight Loss',
            PAYMENT_DATE: 'Payment Date',
            PAYMENT_AMOUNT: '90% Payment',
            DEBIT_NOTE: 'Debit Note',
            DEBIT_NOTE_GST: 'Debit Note GST',
            GST_PENDING: 'GST Pending',
            BALANCE_PENDING: 'Balance Pending',
            NET_BALANCE_PENDING: 'Net Balance Pending',
            TDS: 'TDS'
        };

        // Reformat the data with new headers
        return data.map(item => {
            const renamedItem = {};
            Object.keys(item).forEach(key => {
                const newKey = headerMapping[key] || key; // If header mapping exists, use it
                renamedItem[newKey] = item[key];
            });
            return renamedItem;
        });
    }


    // Function to create an Excel file from JSON data
    function createExcelFile(data) {
        const workbook = XLSX.utils.book_new();

        // Convert JSON data (array of objects) to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);

        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

        return workbook;
    }

    // Download Excel file
    function handleDownload() {
        debugger;
        if (data && data.vouchers.length) {
            // Create an Excel file using the renamed headers
            const workbook = createExcelFile(removeFields(renameHeaders(data.vouchers)));

            // Trigger file download
            XLSX.writeFile(workbook, `Eco Green Sale Report ${moment().format('DD MMM YYYY')}.xlsx`);

        } else {
            toast.error("No data available to download.");
        }
    }

    // Convert the workbook to base64 format
    function workbookToBase64(workbook) {
        const workbookBinary = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
        const buffer = new ArrayBuffer(workbookBinary.length);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < workbookBinary.length; i++) {
            view[i] = workbookBinary.charCodeAt(i) & 0xFF;
        }
        return btoa(String.fromCharCode.apply(null, view));
    }

    // Send Excel file as base64 via API
    function handleSend() {
        if (data && data.vouchers.length) {
            // Create an Excel file using the renamed headers
            const workbook = createExcelFile(removeFields(renameHeaders(data.vouchers)));

            // Convert workbook to base64
            const base64Excel = workbookToBase64(workbook);

            // Prepare payload for API
            const payload = {
                fileName: 'voucher_report_' + moment().format('X') + '.xlsx',
                fileData: base64Excel,
            };

            v1_voucher_report(payload).then((res) => {
                if (res.status) {
                    toast.success(res.result)
                }
            })
        } else {
            toast.error("No data available to send.");
        }
    }


    // State and useEffect hooks
    const location = useLocation();
    const { page } = location.state || {};
    const [data, setData] = useState(null);
    const [currentPage, setCurrentPage] = useState(page ? page : 1);
    const [limit, setLimit] = useState(10);
    const [searchModel, setSearchModel] = useState({
        startDate: '',
        endDate: '',
        voucherType: '',
        gateNumber: '',
        warehouse: '',
        party: '',
    });

    useEffect(() => {
        if (searchModel.startDate && searchModel.endDate) {
            v2_voucher_report(searchModel, currentPage, limit).then((res) => {
                if (res.status) {
                    setData(res.result);
                    props.history.push(constant.Authedcomponent.voucherReport.url, {
                        page: currentPage,
                    });
                } else {
                    toast.error(res.error);
                }
            });
        }
    }, [currentPage, limit, searchModel.startDate, searchModel.endDate]);

    useEffect(() => {
        if (searchModel.startDate && searchModel.endDate) {
            setCurrentPage(1);
            v2_voucher_report(searchModel, 1, limit).then((res) => {
                if (res.status) {
                    setData(res.result);
                    props.history.push(constant.Authedcomponent.voucherReport.url, {
                        page: currentPage,
                    });
                } else {
                    toast.error(res.error);
                }
            });
        }
    }, [searchModel, limit]);

    return (
        <div className="page-content">
            <div className="container-fluid">
                {/* start page title */}
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0 font-size-18">Report Management</h4>
                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <a href="javascript: void(0);">DashBoard</a>
                                    </li>
                                    <li className="breadcrumb-item active">Report Management</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end page title */}

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <SearchOptions onSubmit={setSearchModel}
                                    handleDownload={handleDownload}
                                    handleSend={handleSend}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                {data && (
                                    <Report
                                        limit={limit}
                                        data={data}
                                        currentPage={currentPage}
                                        setCurrentPage={setCurrentPage}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default List;

const SearchOptions = (props) => {
    const [state, setState] = useState({
        startDate: { name: 'startDate', value: moment().format('YYYY-MM-DD'), isRequired: true, error: '' },
        endDate: { name: 'endDate', value: moment().format('YYYY-MM-DD'), isRequired: true, error: '' },
        voucherType: { name: 'voucherType', value: '', options: [], isRequired: false, error: '' },
        gateNumber: { name: 'gateNumber', value: '', options: [], isRequired: false, error: '' },
        warehouse: { name: 'warehouse', value: '', options: [], isRequired: false, error: '' },
        party: { name: 'party', value: '', options: [], isRequired: false, error: '' },
    });

    useEffect(() => {
        if (state.warehouse.value) {
            v2_gates(state.warehouse.value).then((res) => {
                if (res.status) {
                    setState(prevState => ({
                        ...prevState,
                        gateNumber: { ...prevState.gateNumber, options: res.result }
                    }));
                }
            })
        }
        else {
            setState(prevState => ({
                ...prevState,
                [state.gateNumber.name]: {
                    ...prevState[state.gateNumber.name],
                    value: '',
                    options: []
                }
            }));

        }
    }, [state.warehouse.value])
    useEffect(() => {
        // Fetch companies
        v2_companies().then(res => {
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    party: { ...prevState.party, options: res.result }
                }));
            }
        });

        // Fetch voucher types
        v2_voucher_type().then(res => {
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    voucherType: { ...prevState.voucherType, options: res.result }
                }));
            }
        });

        // Fetch warehouses
        v2_warehouse().then(res => {
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    warehouse: { ...prevState.warehouse, options: res.result }
                }));
            }
        });
    }, []);

    // Handler for form inputs
    const handleChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: { ...prevState[name], value: value }
        }));
    };

    const handleSearch = () => {
        // Perform search logic with state data
        if (validateFunctionalForm(state, setState)) {
            const model = {
                startDate: state.startDate.value,
                endDate: state.endDate.value,
                voucherType: state.voucherType.value,
                gateNumber: state.gateNumber.value,
                warehouse: state.warehouse.value,
                party: state.party.value,
            };
            props.onSubmit(model)
        }
    };

    const handleReset = () => {
        // Reset the form
        setState({
            startDate: { name: 'startDate', value: moment().format('YYYY-MM-DD'), isRequired: true, error: '' },
            endDate: { name: 'endDate', value: moment().format('YYYY-MM-DD'), isRequired: true, error: '' },
            voucherType: { name: 'voucherType', value: '', options: [], isRequired: false, error: '' },
            gateNumber: { name: 'gateNumber', value: '', options: [], isRequired: false, error: '' },
            warehouse: { name: 'warehouse', value: '', options: [], isRequired: false, error: '' },
            party: { name: 'party', value: '', options: [], isRequired: false, error: '' },
        });
    };

    return (
        <form className="row gy-12 gx-12 align-items-center">
            {/* Voucher Type */}
            <div className="col-sm-4 mb-2">
                <label htmlFor="voucherTypeSelect">Voucher Type</label>
                <div className="input-group">
                    <select
                        id="voucherTypeSelect"
                        name="voucherType"
                        className="form-select"
                        value={state.voucherType.value}
                        onChange={handleChange}
                    >
                        <option value="">Select Voucher Type</option>
                        {state.voucherType.options.map(option => (
                            <option key={option.ID} value={option.ID}>{option.NAME}</option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Company */}
            <div className="col-sm-4 mb-2">
                <label htmlFor="companySelect">Party</label>
                <div className="input-group">
                    <select
                        id="companySelect"
                        name="party"
                        className="form-select"
                        value={state.party.value}
                        onChange={handleChange}
                    >
                        <option value="">Select Party</option>
                        {state.party.options.map(option => (
                            <option key={option.ID} value={option.ID}>{option.COMPANY_NAME}</option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Date Range */}
            <div className="col-sm-4 mb-2">
                <label>Date Range</label>
                <div className="row">
                    <div className="col">
                        <div className="input-group">
                            <input
                                type="date"
                                id="startDate"
                                name="startDate"
                                className="form-control"
                                value={state.startDate.value}
                                onChange={handleChange}
                                placeholder="From"
                            />
                        </div>
                    </div>
                    <div className="col">
                        <div className="input-group">
                            <input
                                type="date"
                                id="endDate"
                                name="endDate"
                                className="form-control"
                                value={state.endDate.value}
                                onChange={handleChange}
                                placeholder="To"
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Warehouse */}
            <div className="col-sm-4 mb-2">
                <label htmlFor="warehouseSelect">Warehouse</label>
                <div className="input-group">
                    <select
                        id="warehouseSelect"
                        name="warehouse"
                        className="form-select"
                        value={state.warehouse.value}
                        onChange={handleChange}
                    >
                        <option value="">Select Warehouse</option>
                        {state.warehouse.options.map(option => (
                            <option key={option.ID} value={option.ID}>{option.NAME}</option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Entry Gate */}
            <div className="col-sm-4 mb-2">
                <label htmlFor="entryGateSelect">Entry Gate</label>
                <div className="input-group">
                    <select
                        id="entryGateSelect"
                        name="gateNumber"
                        className="form-select"
                        value={state.gateNumber.value}
                        onChange={handleChange}
                    >
                        <option value="">Select Entry Gate</option>
                        {state.gateNumber.options.map(option => (
                            <option key={option.ID} value={option.ID}>{option.NAME}</option>
                        ))}
                    </select>
                </div>
            </div>

            {/* Buttons */}
            <div className="col-sm-4 mb-2 d-flex align-items-center" style={{ top: '14px' }}>
                <button
                    type="button"
                    className="btn btn-success waves-effect btn-label waves-light me-2"
                    onClick={handleSearch}
                >
                    <i className="bx bx-search label-icon" /> Search
                </button>
                <button
                    type="button"
                    className="btn btn-outline-danger me-2"
                    onClick={handleReset}
                >
                    <i className="bx bx-reset label-icon" />
                    Reset
                </button>
                <button
                    type="button"
                    className="btn btn-outline-info me-2"
                    onClick={props.handleDownload}
                >
                    <i className="bx bx-download label-icon" />
                </button>
                <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={props.handleSend}
                >
                    <i className="bx bx-send label-icon" />
                </button>
            </div>

        </form>
    );
};

