import axios from 'axios'
import { getAuthHeader } from '../../utils';

export const v2_roles = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/roles`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v2_states = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/states`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v2_state_districts = async (stateId) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/districts/${stateId}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v2_company_industry = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/company-industry`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v2_company_business_category = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/company-business-category`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v2_company_type = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/company-type`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}



export const v2_voucher_type = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/voucher-type`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

