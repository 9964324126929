import axios from "axios";
import { getAuthHeader } from "../../utils";

export const v3_add_gate = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/gate`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_gates = async (page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/gate/${page}/${limit}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}




export const v3_edit_gate = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/gate`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}




export const v3_gate_visibility = async (id, visibility) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/gate/${id}`;
    return axios.patch(url, { visibility: visibility }, getAuthHeader()).then((res) => {
        return res.data;
    })
}



