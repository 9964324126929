import React from 'react'
import Layout from '../../../components/common/pageLayout'
import Form from '../../../components/Administration/role/form'
import { v3_add_role } from '../../../actions/v3/role'
import constant from '../../../constant'
import { toast } from 'react-toastify'
const Add = (props) => {
    const onSubmit = (model) => {
        v3_add_role(model).then((res) => {
            if (res.status) {
                toast.success(res.result.message)
                props.history.push(constant.Authedcomponent.listRole.url)
            }
            else {
                toast.error(res.error)
            }
        })
    }
    return (
        <React.Fragment>
            <Layout
                title={'Add Roles'}>
                <Form
                    title={'Add Roles'}
                    onSubmit={onSubmit}
                    buttonName={'Create Role'}
                />
            </Layout>

        </React.Fragment>
    )
}

export default Add