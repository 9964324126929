import React from 'react'
import { Link, withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import Paginations from '../common/paginations';
import constant from '../../constant';

const List = ({ data, setCurrentPage, limit }) => {

    const { stocks, total, page, totalPages } = data;
    return (
        <React.Fragment>
            <div className="table-responsive">
                <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                        <tr>
                            <th scope="col" style={{ width: 70 }}>
                                #
                            </th>
                            <th scope="col">Stock Detail</th>
                            <th scope="col">Remarks</th>
                            <th scope="col">Warehouse</th>
                            <th scope="col">Modified By </th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stocks?.map((item, index) => {
                            return (
                                <tr>
                                    <td>
                                        <div className="avatar-xs">
                                            <span className="avatar-title rounded-circle">{index + 1}</span>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 className="font-size-14 mb-1">
                                            <a href="javascript:void(0);" className="text-dark">
                                                {item.CATEGORY}
                                            </a>
                                            <p className="text-muted mb-0">
                                                {item.SUB_PRODUCT_NAME} ( {item.PRODUCT_NAME} )
                                            </p>
                                            <p className="text-muted mb-0">
                                                {item.WEIGHT} KG
                                            </p>
                                        </h5>
                                    </td>
                                    <td><p>{item.REMARKS || '--'}</p></td>
                                    <td>
                                        <h5 className="font-size-14 mb-1">
                                            <a href="javascript:void(0);" className="text-dark">
                                                {item.WAREHOUSE_NAME}
                                            </a>
                                        </h5>
                                    </td>
                                    <td>{item.MODIFIED_BY_NAME}</td>
                                    <td>
                                        {!item.VOUCHER_ID ? (<ul className="list-inline font-size-20 contact-links mb-0">

                                            <li className="list-inline-item px-2">
                                                <Link
                                                    to={{
                                                        pathname: constant.Authedcomponent.editStock.url,
                                                        state: {
                                                            data: btoa(JSON.stringify(item)),
                                                            page: page
                                                        }  // Passing the entire `item` object as data
                                                    }}
                                                    title="Edit Stock"
                                                >
                                                    <i className="bx bx-edit" />
                                                </Link>
                                            </li>
                                        </ul>
                                        ) : <p>Not Editable</p>}

                                    </td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            </div>
            <Paginations
                limit={limit}
                totalCount={total}
                range={5}
                currentPage={page}
                onPageChange={setCurrentPage}
            />
        </React.Fragment>


    )
}

export default withRouter(List)