import React, { useEffect, useState } from 'react'
import Layout from '../../../components/common/pageLayout'
import { toast } from 'react-toastify'
import ListCompanyType from '../../../components/MasterData/CompanyType/list'
import constant from '../../../constant'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { v3_company_types, v3_company_types_visibility } from '../../../actions/v3/masterData'
const List = (props) => {

    const location = useLocation();
    const { page } = location.state || {};
    const [data, setData] = useState(null)
    const [currentPage, setCurrentPage] = useState(page ? page : 1)
    const [limit, setLimit] = useState(10)

    useEffect(() => {
        v3_company_types(currentPage, limit).then((res) => {
            if (res.status) {
                setData(res.result);
                props.history.push(constant.Authedcomponent.listCompanyTypeMasterData.url, {
                    page: currentPage // Replace 'yourData' with the data you want to pass
                });
            }
            else {
                toast.error(res.error)
            }
        })
    }, [currentPage, limit])

    const onChangeVisibility = (record) => {
        // Toggle the LOGIN_STATUS between 'A' (Active) and 'I' (Inactive)
        const newStatus = record.STATUS === 'A' ? 'I' : 'A';

        // Call the v3_user_access function with USER_ID and the new status
        v3_company_types_visibility(record.ID, newStatus).then((res) => {
            if (res.status) {
                // Update the state with the new LOGIN_STATUS
                setData(prevState => ({
                    ...prevState, // Preserve other parts of the state
                    companyTypes: prevState.companyTypes.map(item => {
                        if (item.ID === record.ID) {
                            return {
                                ...item,
                                STATUS: newStatus // Update the LOGIN_STATUS
                            };
                        }
                        return item;
                    })
                }));
                

                // Display a success message
                toast.success(res.result.message);
            }
        });
    };

    return (
        <Layout
            title={'Company Type List'}
        >
            {data && <ListCompanyType
                onChangeVisibility={onChangeVisibility}
                limit={limit}
                data={data}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />}
        </Layout>
    )
}

export default List