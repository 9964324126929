import React, { useEffect, useState } from 'react'
import Layout from '../../../components/common/pageLayout'
import { toast } from 'react-toastify'
import ListRole from '../../../components/Administration/logs/list'
import { v3_activity_logs } from '../../../actions/v1/auth'
import constant from '../../../constant'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'
import { getStorage } from '../../../utils'
const List = (props) => {
    const [ud, set_ud] = useState(JSON.parse(atob(getStorage(constant.keys.userDetails))))

    const location = useLocation();

    const { page } = location.state || {};

    const [data, setData] = useState(null)
    const [currentPage, setCurrentPage] = useState(page ? page : 1)
    const [limit, setLimit] = useState(50)
    const [fromDate, setFromDate] = useState(moment().format('YYYY MM DD'))
    const [toDate, setToDate] = useState(moment().format('YYYY MM DD'))


    useEffect(() => {
        v3_activity_logs(limit, currentPage, fromDate, toDate).then((res) => {
            if (res.status) {
                setData(res.result);
                props.history.push(constant.Authedcomponent.activityLog.url, {
                    page: currentPage
                });
            }
            else {
                toast.error(res.error)
            }
        })
    }, [currentPage, limit, fromDate, toDate])

    return (
        <Layout
            title={'Activity Log'}
        >
            {/* <div className='row justify-content-end'
            >
                <div className="col-3">
                    <div className="mb-3">
                        <label htmlFor="basicpill-fromDate-input">
                            From Date
                        </label>
                        <input
                            type="date"
                            className="form-control"
                            id="basicpill-fromDate-input"
                            placeholder="Enter Role"
                            name={'fromDate'}
                            value={fromDate}
                            onChange={(e) => { setFromDate(e.target.value) }}

                        />
                    </div>
                </div>
                <div className="col-3">

                    <div className="mb-3">
                        <label htmlFor="basicpill-toDate-input">
                            To Date
                        </label>
                        <input
                            type="date"
                            className="form-control"
                            id="basicpill-toDate-input"
                            placeholder="Enter Role"
                            name={'toDate'}
                            value={toDate}
                            onChange={(e) => { setToDate(e.target.value) }}
                        />
                    </div>
                </div>
            </div> */}
            {data && <ListRole
                userName={ud.fullName}
                limit={limit}
                data={data}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />}
        </Layout>
    )
}

export default List