import React from 'react'
import Layout from '../../../components/common/pageLayout'
import Form from '../../../components/Product/SubProduct/add'
import constant from '../../../constant'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { v3_edit_sub_product } from '../../../actions/v3/productManagement'
const Add = (props) => {

    const location = useLocation();
    const { data, page } = location.state || {};
    const onSubmit = (model) => {
        model.previousName = JSON.parse(atob(data))['NAME']
        v3_edit_sub_product(model).then((res) => {
            if (res.status) {
                toast.success(res.result.message)
                props.history.push(constant.Authedcomponent.listSubProduct.url, {
                    page: page // Replace 'yourData' with the data you want to pass
                });
            }
            else {
                toast.error(res.error)
            }
        })
    }

    const onCancel = () => {
        props.history.push(constant.Authedcomponent.listSubProduct.url, {
            page: page // Replace 'yourData' with the data you want to pass
        });
    }
    return (
        <React.Fragment>
            <Layout
                title={'Edit Product'}>
                <Form
                    title={'Edit Product'}
                    onSubmit={onSubmit}
                    data={JSON.parse(atob(data))}
                    buttonName={'Save Product'}
                    onCancel={onCancel}


                />
            </Layout>

        </React.Fragment>
    )
}

export default Add