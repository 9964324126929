import axios from 'axios'
import { getAuthHeader } from '../../utils';

export const v3_add_user = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/user`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_edit_user = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/user`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_users = async (page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/user/${page}/${limit}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}



export const v3_user_access = async (userName, userId, userAccess) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/user/${userId}`;
    return axios.patch(url, { userAccess: userAccess, userName: userName }, getAuthHeader()).then((res) => {
        return res.data;
    })
}