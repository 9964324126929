import React from 'react'
import constant from '../../constant'

const layout = ({ children }) => {
    return (
        <React.Fragment>
             <div className="account-pages pt-sm-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="card overflow-hidden">
                                <div className="bg-success bg-soft">
                                    <div className="row">
                                        <div className="col-7">
                                            <div className="text-black p-4">
                                                <a
                                                    href="javascript:void(0)"
                                                    classname="text-success"
                                                    style={{
                                                        // color: "#dc4237",
                                                        fontFamily: "nunito",
                                                        fontSize: 22,
                                                        fontWeight: 700
                                                    }}
                                                >
                                                    ECO GREEN
                                                </a>
                                                <h5 className="text-black">Welcome Back !</h5>
                                                <p>Sign in to continue to Eco Green Administration Control Panel.</p>
                                            </div>
                                        </div>
                                        <div className="col-5 align-self-end">
                                            <img src="assets/images/profile-img.png" alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body pt-0">
                                    <div className="auth-logo">
                                        <a href={constant.Authedcomponent.dashboard.url} className="auth-logo-light">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img src="/assets/images/logo-light.png" alt="" className="rounded-circle" height="60" />
                                                </span>
                                            </div>
                                        </a>

                                        <a href={constant.Authedcomponent.dashboard.url} className="auth-logo-dark">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img src="/assets/images/logo-light.png" alt="" className="rounded-circle" height="60" />
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="p-2">
                                       {children}
                                    </div>

                                </div>
                            </div>
                            <div className="text-center">

                                <div>
                                    <p>
                                        <a href="https://www.ecogreen.com/" target="_blank">
                                            <img
                                                src="/assets/images/logo.png"
                                                style={{ width: 112, marginBottom: 12 }}
                                            />
                                        </a>
                                        <br /> Powered by{" "}
                                        <a
                                            href="https://shashwatco.com/"
                                            // title="Technology company with products in HRMS AI and Blockchain Noida, Delhi India"
                                        >
                                            Shashwat Web Technologies Pvt Ltd.
                                        </a>
                                    </p>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </React.Fragment>
    )
}

export default layout