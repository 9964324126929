import React from 'react'
import { Link, withRouter } from 'react-router-dom/cjs/react-router-dom.min'
import Paginations from '../../common/paginations';
import constant from '../../../constant';

const List = ({ data, setCurrentPage, limit, onChangeVisibility }) => {

    const { subProducts, total, page, totalPages } = data;
    return (
        <React.Fragment>
            <div className="table-responsive">
                <table className="table align-middle table-nowrap table-hover">
                    <thead className="table-light">
                        <tr>
                            <th scope="col" style={{ width: 70 }}>
                                #
                            </th>
                            <th scope="col">Sub Product Name</th>
                            <th scope="col">Product Name</th>
                            <th scope="col">Product Details</th>
                            <th scope="col">Modified By </th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {subProducts?.map((item, index) => {
                            return (
                                <tr>
                                    <td>
                                        <div className="avatar-xs">
                                            <span className="avatar-title rounded-circle">{item.NAME.charAt(0)}</span>
                                        </div>
                                    </td>
                                    
                                    <td>
                                        <h5 className="font-size-14 mb-1">
                                            <a href="javascript:void(0);" className="text-dark">
                                                {item.NAME}
                                            </a>
                                        </h5>
                                        <p className="text-muted mb-0">
                                            {'Visibility'}{" "}
                                            <span className={`badge border border-light rounded-circle ${item.STATUS === 'A' ? 'bg-success' : 'bg-warning'} p-1`} >
                                                <span className="visually-hidden" />
                                            </span>
                                        </p>
                                    </td>
                                    <td>{item.PRODUCT_NAME}</td>
                                    <td>{item.DETAIL}</td>
                                    <td>{item.MODIFIED_BY_NAME}</td>

                                    <td>
                                        <ul className="list-inline font-size-20 contact-links mb-0">
                                            <li className="list-inline-item px-2">
                                                <Link to={{
                                                    pathname: constant.Authedcomponent.editSubProduct.url,
                                                    state: {
                                                        data: btoa(JSON.stringify(item)),
                                                        page: page
                                                    }  // Passing the entire `item` object as data
                                                }} title="Edit Company Type">
                                                    <i className="bx bx-edit" />
                                                </Link>
                                            </li>
                                            <li className="list-inline-item px-2">
                                                <a
                                                    href="javascript:void(0);"
                                                    style={{ color: "red" }}
                                                    title="Delete User"
                                                    onClick={() => { onChangeVisibility(item) }}
                                                >
                                                    <i className={`bx ${item.STATUS === 'A' ? 'bx-check-circle' : 'bx-error-circle'}`} />

                                                </a>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            </div>
            <Paginations
                limit={limit}
                totalCount={total}
                range={5}
                currentPage={page}
                onPageChange={setCurrentPage}
            />
        </React.Fragment>


    )
}

export default withRouter(List)