import axios from 'axios'
import { getAuthHeader } from '../../utils';

export const v3_add_voucher_step_one = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/voucher/1`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_add_voucher_step_two = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/voucher/2`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_assignable_vouchers = async (page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/voucher/assign/${page}/${limit}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_assigned_vouchers = async (page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/voucher/${page}/${limit}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_assign_voucher = async (voucherId, invoiceNumber, assignee) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/voucher/assign/${voucherId}`;
    return axios.patch(url, { assignee: assignee, invoiceNumber: invoiceNumber }, getAuthHeader()).then((res) => {
        return res.data;
    })
}

