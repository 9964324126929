import React from 'react';

const Paginations = ({ limit, totalCount, range, currentPage, onPageChange }) => {
    const totalPages = Math.ceil(totalCount / limit);

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            onPageChange(page);
        }
    };

    const renderPagination = () => {
        const pages = [];

        let startPage = 1;
        if (totalPages > range && currentPage > 1) {
            startPage = Math.min(currentPage, totalPages - range + 1);
        }

        const endPage = Math.min(startPage + range - 1, totalPages);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <li key={i} className={`page-item ${i === Number(currentPage) ? 'active' : ''}`}>
                    <a className="page-link" href="javascript:void(0)" onClick={() => handlePageChange(i)}>
                        {i}
                    </a>
                </li>
            );
        }

        return pages;
    };

    return (
        <div className="row">
            <div className="col-lg-12">
                <ul className="pagination pagination-rounded justify-content-center mt-4">
                    <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                        <a href="javascript:void(0)" className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                            <i className="mdi mdi-chevron-left" />
                        </a>
                    </li>
                    {renderPagination()}
                    <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                        <a href="javascript:void(0)" className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                            <i className="mdi mdi-chevron-right" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Paginations;
