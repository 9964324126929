import React from 'react'
import Layout from '../../../components/common/pageLayout'
import Form from '../../../components/MasterData/VoucherType/add'
import { v3_add_voucher_type } from '../../../actions/v3/masterData'
import constant from '../../../constant'
import { toast } from 'react-toastify'
const Add = (props) => {

    const onSubmit = (model) => {
        v3_add_voucher_type(model).then((res) => {
            if (res.status) {
                toast.success(res.result.message)
                props.history.push(constant.Authedcomponent.listVoucherTypeMasterData.url)
            }
            else {
                toast.error(res.error)

            }
        })
    }
    return (
        <React.Fragment>
            <Layout
                title={'Add Voucher Type'}
            >
                <Form
                    title={'Add Voucher Type'}
                    onSubmit={onSubmit}
                    buttonName={'Create Voucher Type'}
                />
            </Layout>
        </React.Fragment>
    )
}

export default Add