import React from 'react'
import Layout from '../../components/common/pageLayout'
import Form from '../../components/Transporter/add'
import constant from '../../constant'
import { toast } from 'react-toastify'
import { v3_add_transporter } from '../../actions/v3/trasnporterManagement'
const Add = (props) => {

    const onSubmit = (model) => {
        v3_add_transporter(model).then((res) => {
            if (res.status) {
                toast.success(res.result.message)
                props.history.push(constant.Authedcomponent.listTransporter.url)
            }
            else {
                toast.error(res.error)

            }
        })
    }
    const onCancel = () => {
        props.history.push(constant.Authedcomponent.listTransporter.url);
    }
    return (
        <React.Fragment>
            <Layout
                title={'Add Transporter'}
            >
                <Form
                    title={'Add Transporter'}
                    onSubmit={onSubmit}
                    buttonName={'Create Transporter'}
                    onCancel={onCancel}
                />
            </Layout>
        </React.Fragment>
    )
}

export default Add