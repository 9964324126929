import axios from 'axios'
import { getAuthHeader } from '../../utils';

export const v2_companies = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/company`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v2_company_count = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/count/company`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
