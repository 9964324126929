import React from 'react'
import Layout from '../../../components/common/pageLayout'
import Form from '../../../components/Product/SubProduct/add'
import constant from '../../../constant'
import { toast } from 'react-toastify'
import { v3_add_sub_product } from '../../../actions/v3/productManagement'
const Add = (props) => {

    const onSubmit = (model) => {
        v3_add_sub_product(model).then((res) => {
            if (res.status) {
                toast.success(res.result.message)
                props.history.push(constant.Authedcomponent.listSubProduct.url)
            }
            else {
                toast.error(res.error)

            }
        })
    }
    const onCancel = () => {
        props.history.push(constant.Authedcomponent.listSubProduct.url);
    }
    return (
        <React.Fragment>
            <Layout
                title={'Add Sub Product'}
            >
                <Form
                    title={'Add Sub Product'}
                    onSubmit={onSubmit}
                    buttonName={'Create Sub Product'}
                    onCancel={onCancel}

                />
            </Layout>
        </React.Fragment>
    )
}

export default Add