import React from 'react'
import Layout from '../../components/common/pageLayout'
import Form from '../../components/Company/add'
import constant from '../../constant'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { v3_edit_company } from '../../actions/v3/companyManagement'
const Add = (props) => {

    const location = useLocation();
    const { data, page } = location.state || {};
    const onSubmit = (model) => {
        model.previousName = JSON.parse(atob(data))['NAME']
        v3_edit_company(model).then((res) => {
            if (res.status) {
                toast.success(res.result.message)
                props.history.push(constant.Authedcomponent.listCompany.url, {
                    page: page // Replace 'yourData' with the data you want to pass
                });
            }
            else {
                toast.error(res.error)
            }
        })
    }
    return (
        <React.Fragment>
            <Layout
                title={'Edit Company'}>
                <Form
                    title={'Edit Company'}
                    onSubmit={onSubmit}
                    data={JSON.parse(atob(data))}
                    buttonName={'Save Company'}

                />
            </Layout>

        </React.Fragment>
    )
}

export default Add