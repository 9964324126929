import React from 'react'
import Layout from '../../components/common/pageLayout'
import Form from '../../components/Company/add'
import constant from '../../constant'
import { toast } from 'react-toastify'
import { v3_add_company } from '../../actions/v3/companyManagement'
const Add = (props) => {

    const onSubmit = (model) => {
        v3_add_company(model).then((res) => {
            if (res.status) {
                toast.success(res.result.message)
                props.history.push(constant.Authedcomponent.listCompany.url)
            }
            else {
                toast.error(res.error)

            }
        })
    }
    return (
        <React.Fragment>
            <Layout
                title={'Add Company'}
            >
                <Form
                    title={'Add Company'}
                    onSubmit={onSubmit}
                    buttonName={'Create Company'}
                />
            </Layout>
        </React.Fragment>
    )
}

export default Add