import React, { useState } from 'react';
import { getStorage, validateFunctionalForm } from '../../utils';
import constant from '../../constant';

const Login = (props) => {
    const [ud, set_ud] = useState(JSON.parse(atob(getStorage(constant.keys.userDetails))))

    const [state, setState] = useState({
        password: { name: 'password', value: '', isRequired: true, error: '' }
    });

    const [showPassword, setShowPassword] = useState(false);

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value,
                error: prevState[name].isRequired && !value ? 'This field is required' : ''
            }
        }));
    };

    const toggleShowPassword = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (validateFunctionalForm(state, setState)) {
            const model = {
                id: ud.id,
                password: state.password.value,

            }
            props.onSubmit(model)
        }

    }
    return (
        <React.Fragment>
            <form className="form-horizontal" onSubmit={onSubmit}>

                <div className="user-thumb text-center mb-4">
                    <img
                        src="/assets/images/users/avatar-2.jpg"
                        className="rounded-circle img-thumbnail avatar-md"
                        alt="thumbnail"
                    />
                    <h5 className="font-size-15 mt-3">{ud.fullName}</h5>
                </div>

                <div className="mb-3">
                    <label className="form-label">Password</label>
                    <div className="input-group auth-pass-inputgroup">
                        <input
                            className={state.password.error ? "form-control is-invalid" : "form-control"}
                            type={showPassword ? "text" : "password"}
                            name={state.password.name}
                            value={state.password.value}
                            placeholder="*********"
                            onChange={onChange}
                            aria-label="Password" aria-describedby="password-addon"
                        />
                        <button className="btn btn-light " type="button" id="password-addon" onClick={toggleShowPassword}><i className={!showPassword ? "mdi mdi-eye-outline" : "mdi mdi-eye-outline"}></i></button>
                    </div>
                </div>

                <div className="mt-3 d-grid">
                    <button className="btn btn-success waves-effect waves-light" type="submit">Unlock</button>
                </div>
            </form>
           


        </React.Fragment >
    );
}

export default Login;
