import React, { useEffect } from 'react'
import constant from '../../../constant';
import { removeStorage } from '../../../utils';
import styles from './logout.module.css'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
const Logout = () => {
    useEffect(() => {
        document.title = 'Logout';
        removeStorage('rsc_cache')
        removeStorage(constant.keys.userToken);
        removeStorage(constant.keys.userDetails);
        localStorage.clear();
    }, [])
    return (
        <div className={styles.loggedOutContainer}>
            <div className={styles.overlay}></div> {/* Optional: overlay for a background */}
            <div className={styles.content}>
                <div className={styles.logo}>
                    <img src="/assets/images/logo.png" alt="Logo" /> {/* Replace with your logo path */}
                </div>
                <h2 className={styles.title}>Welcome Back!</h2>
                <p className={styles.subtitle}>Please <Link to={constant.nonAuthedcomponent.login.url}>log in</Link> to continue.</p>

                {/* <Login onSubmit={props.onSubmit} /> Pass the onSubmit prop down to the Login component */}
                {/* 
                <div className={styles.footerText}>
                    <p>Don't have an account? <a href="/signup">Sign up now</a></p>
                </div> */}
            </div>
        </div>

    )
}

export default Logout