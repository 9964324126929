import axios from "axios";
import { getAuthHeader } from "../../utils";

export const v3_add_company_type = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/company-type`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_company_types = async (page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/company-type/${page}/${limit}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_company_types_visibility = async (id, visibility) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/company-type/${id}`;
    return axios.patch(url, { visibility: visibility }, getAuthHeader()).then((res) => {
        return res.data;
    })
}




export const v3_edit_company_types = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/company-type`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}



export const v3_add_state = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/state`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_states = async (page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/state/${page}/${limit}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_states_visibility = async (id, visibility) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/state/${id}`;
    return axios.patch(url, { visibility: visibility }, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_edit_states = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/state`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_all_states = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/states`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}




export const v3_add_district = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/district`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_districts = async (page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/district/${page}/${limit}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_districts_visibility = async (id, visibility) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/district/${id}`;
    return axios.patch(url, { visibility: visibility }, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_edit_districts = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/district`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_add_company_business_category = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/company-business-category`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_company_business_categories = async (page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/company-business-category/${page}/${limit}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_company_business_categories_visibility = async (id, visibility) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/company-business-category/${id}`;
    return axios.patch(url, { visibility: visibility }, getAuthHeader()).then((res) => {
        return res.data;
    })
}




export const v3_edit_company_business_categories = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/company-business-category`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}




export const v3_add_company_industry = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/company-industry`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_company_industry = async (page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/company-industry/${page}/${limit}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_company_industry_visibility = async (id, visibility) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/company-industry/${id}`;
    return axios.patch(url, { visibility: visibility }, getAuthHeader()).then((res) => {
        return res.data;
    })
}




export const v3_edit_company_industry = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/company-industry`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}



export const v3_add_voucher_type = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/voucher-type`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_voucher_types = async (page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/voucher-type/${page}/${limit}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_voucher_types_visibility = async (id, visibility) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/voucher-type/${id}`;
    return axios.patch(url, { visibility: visibility }, getAuthHeader()).then((res) => {
        return res.data;
    })
}

export const v3_edit_voucher_types = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/master-data/voucher-type`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}

