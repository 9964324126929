import React, { useEffect, useState } from 'react'
import Layout from '../../../components/common/pageLayout'
import { v3_user_access, v3_users } from '../../../actions/v3/user'
import { toast } from 'react-toastify'
import ListUser from '../../../components/Administration/users/list'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import constant from '../../../constant'
const List = (props) => {
    const location = useLocation();

    const { page } = location.state || {};

    const [data, setData] = useState(null)
    const [currentPage, setCurrentPage] = useState(page ? page : 1)
    const [limit, setLimit] = useState(10)

    useEffect(() => {
        v3_users(currentPage, limit).then((res) => {
            if (res.status) {
                setData(res.result);
                props.history.push(constant.Authedcomponent.listUser.url, {
                    page: currentPage // Replace 'yourData' with the data you want to pass
                });
            }
            else {
                toast.error(res.error)
            }
        })

    }, [currentPage, limit])

    const onChangeAccess = (record) => {
        // Toggle the LOGIN_STATUS between 'A' (Active) and 'I' (Inactive)
        const newStatus = record.LOGIN_STATUS === 'A' ? 'I' : 'A';

        // Call the v3_user_access function with USER_ID and the new status
        v3_user_access(record.USER_NAME, record.USER_ID, newStatus).then((res) => {
            if (res.status) {
                // Update the state with the new LOGIN_STATUS
                setData(prevState => ({
                    ...prevState, // Preserve other parts of the state
                    users: prevState.users.map(item => {
                        if (item.USER_ID === record.USER_ID) {
                            return {
                                ...item,
                                LOGIN_STATUS: newStatus // Update the LOGIN_STATUS
                            };
                        }
                        return item;
                    })
                }));


                // Display a success message
                toast.success(res.result.message);
            }
        });
    };

    return (
        <Layout
            title={'Admin User List'}
        >
            {data && <ListUser
                onChangeAccess={onChangeAccess}
                limit={limit}
                data={data}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />}
        </Layout>
    )
}

export default List