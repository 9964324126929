import axios from "axios";
import { getAuthHeader } from "../../utils";

export const v3_add_stock = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/stock`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_stocks = async (page, limit) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/stock/${page}/${limit}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_edit_stock = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/stock`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}
