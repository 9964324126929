import React from 'react'
import Layout from '../../components/common/pageLayout'
import Form from '../../components/Voucher/edit'
import constant from '../../constant'
import { toast } from 'react-toastify'
import { v3_add_voucher_step_two } from '../../actions/v3/voucherManagement'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
const Add = (props) => {
    const location = useLocation();
    const { data, page } = location.state || {};
    const onSubmit = (model) => {
        v3_add_voucher_step_two(model).then((res) => {
            if (res.status) {
                toast.success(res.result.message)
                props.history.push(constant.Authedcomponent.listVoucher.url)
            }
            else {
                toast.error(res.error)

            }
        })
    }

    const onCancel = () => {
        props.history.push(constant.Authedcomponent.listVoucher.url)
    }
    return (
        <React.Fragment>
            <Layout
                title={'Edit Voucher'}
            >
                <Form
                    onCancel={onCancel}
                    title={'Save Voucher'}
                    onSubmit={onSubmit}
                    data={JSON.parse(atob(data))}
                    buttonName={'Save Voucher'}
                />
            </Layout>
        </React.Fragment>
    )
}

export default Add