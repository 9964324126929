import axios from 'axios'
import { getAuthHeader } from '../../utils';

export const v2_warehouse = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/warehouse`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v2_warehouse_count = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/count/warehouse`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}
