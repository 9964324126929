import axios from "axios";
import { getAuthHeader } from "../../utils";

export const v3_add_company = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/company`;
    return axios.post(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v3_companies = async (page, limit, keyword) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/company/${page}/${limit}?keyword=${keyword}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}




export const v3_edit_company = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/company`;
    return axios.put(url, model, getAuthHeader()).then((res) => {
        return res.data;
    })
}




export const v3_company_visibility = async (id, visibility) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v3/company/${id}`;
    return axios.patch(url, { visibility: visibility }, getAuthHeader()).then((res) => {
        return res.data;
    })
}



