import React, { useState } from 'react';
import { validateFunctionalForm } from '../../utils';

const Login = (props) => {
    const [state, setState] = useState({
        user_name: { name: 'user_name', value: '', isRequired: true, error: '' },
        password: { name: 'password', value: '', isRequired: true, error: '' }
    });

    const [showPassword, setShowPassword] = useState(false);

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value,
                error: prevState[name].isRequired && !value ? 'This field is required' : ''
            }
        }));
    };

    const toggleShowPassword = () => {
        setShowPassword(prevShowPassword => !prevShowPassword);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (validateFunctionalForm(state, setState)) {
            const model = {
                username: state.user_name.value,
                password: state.password.value,

            }
            props.onSubmit(model)
        }

    }
    return (
        <React.Fragment>
            <form className="form-horizontal" onSubmit={onSubmit}>

                <div className="mb-3">
                    <label for="username" className="form-label">Username</label>
                    <input
                        className={state.user_name.error ? "form-control is-invalid" : "form-control"}
                        type="text"
                        name={state.user_name.name}
                        value={state.user_name.value}
                        placeholder="demo"
                        onChange={onChange}
                    />
                </div>

                <div className="mb-3">
                    <label className="form-label">Password</label>
                    <div className="input-group auth-pass-inputgroup">
                        <input
                            className={state.password.error ? "form-control is-invalid" : "form-control"}
                            type={showPassword ? "text" : "password"}
                            name={state.password.name}
                            value={state.password.value}
                            placeholder="*********"
                            onChange={onChange}
                            aria-label="Password" aria-describedby="password-addon"
                        />
                        <button className="btn btn-light " type="button" id="password-addon" onClick={toggleShowPassword}><i className={!showPassword ? "mdi mdi-eye-outline" : "mdi mdi-eye-outline"}></i></button>
                    </div>
                </div>

                {/* <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="remember-check" />
                    <label className="form-check-label" for="remember-check">
                        Remember me
                    </label>
                </div> */}

                <div className="mt-3 d-grid">
                    <button className="btn btn-success waves-effect waves-light" type="submit">Log In</button>
                </div>


                {/* <div className="mt-4 text-center">
                    <a href="auth-recoverpw.html" className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</a>
                </div> */}
            </form>

        </React.Fragment >
    );
}

export default Login;
