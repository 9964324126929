import React, { useEffect, useState } from 'react'
import { validateFunctionalForm } from '../../utils';
import moment from 'moment';
import { v2_voucher_type } from '../../actions/v2/masterData';
import { v2_products, v2_sub_products } from '../../actions/v2/product';
import { v2_transporter_vehicles, v2_transporters } from '../../actions/v2/transporter';
import { v2_gates } from '../../actions/v2/gate';
import { v2_warehouse } from '../../actions/v2/warehouse';
import { v2_companies } from '../../actions/v2/company';

const Form = (props) => {
    const [state, setState] = useState({
        date: { name: 'date', value: props.data ? props.data.DATE : moment().format('YYYY-MM-DD'), isRequried: true, error: '' },
        voucherType: { name: 'voucherType', value: props.data ? props.data.VOUCHER_TYPE_ID : '', options: [], isRequried: true, error: '' },
        gateNumber: { name: 'gateNumber', value: props.data ? props.data.GATE_ID : '', options: [], isRequried: true, error: '' },
        invoiceNumber: { name: 'invoiceNumber', value: props.data ? props.data.INVOICE_NUMBER : '', isRequried: true, error: '' },
        product: { name: 'product', value: props.data ? props.data.PRODUCT_ID : '', options: [], isRequried: true, error: '' },
        subProduct: { name: 'subProduct', value: props.data ? props.data.SUB_PRODUCT_ID : '', options: [], isRequried: true, error: '' },
        challanWeight: { name: 'challanWeight', value: props.data ? props.data.CHALLAN_WEIGHT : '', isRequried: true, error: '' },
        vehicle: { name: 'vehicle', value: props.data ? props.data.VEHICLE_ID : '', options: [], isRequried: true, error: '' },
        transporter: { name: 'transporter', value: props.data ? props.data.TRANSPORTER_ID : '', options: [], isRequried: true, error: '' },
        warehouse: { name: 'warehouse', value: props.data ? props.data.WAREHOUSE_ID : '', options: [], isRequried: true, error: '' },
        invoiceRate: { name: 'invoiceRate', value: props.data ? props.data.INVOICE_RATE : '', isRequried: true, error: '' },
        party: { name: 'party', value: props.data ? props.data.PARTY_NAME : '', options: [], isRequried: true, error: '' },
        mill: { name: 'mill', value: props.data ? props.data.MILL : '', options: [], isRequried: true, error: '' },
        source: { name: 'source', value: props.data ? props.data.SOURCE : '', options: [], isRequried: true, error: '' },



    })

    const onChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                value: value,
                error: prevState[name].isRequired && !value ? 'This field is required' : ''
            }
        }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (validateFunctionalForm(state, setState)) {
            const model = {
                date: state.date.value,
                invoiceNumber: state.invoiceNumber.value,
                product: state.product.value,
                subProduct: state.subProduct.value,
                challanWeight: state.challanWeight.value,
                vehicleId: state.vehicle.value,
                gate: state.gateNumber.value,
                transporter: state.transporter.value,
                voucherType: state.voucherType.value,
                warehouseId: state.warehouse.value,
                invoiceRate: state.invoiceRate.value,

            }
            if (props.data) {
                model.id = props.data.ID
            }
            props.onSubmit(model)
        }
    }
    const setOptions = (name, options) => {
        setState(prevState => ({
            ...prevState,
            [name]: {
                ...prevState[name],
                options: options
            }
        }));
    };
    useEffect(() => {
        v2_voucher_type().then((res) => {
            if (res.status) {
                setOptions(state.voucherType.name, res.result)
            }
        })

        v2_products().then((res) => {
            if (res.status) {
                setOptions(state.product.name, res.result)
            }
        })
        v2_transporters().then((res) => {
            if (res.status) {
                setOptions(state.transporter.name, res.result);
            }
        })


        v2_warehouse().then((res) => {
            if (res.status) {
                setOptions('warehouse', res.result);
            }
        })
        v2_companies().then((res) => {
            if (res.status) {
                setOptions('party', res.result);
                setOptions('mill', res.result);
                setOptions('source', res.result);
            }
        })
    }, [])

    useEffect(() => {
        if (state.product.value) {
            v2_sub_products(state.product.value).then((res) => {
                if (res.status) {
                    setOptions(state.subProduct.name, res.result)
                }
            })
        }
        else {
            setState(prevState => ({
                ...prevState,
                [state.subProduct.name]: {
                    ...prevState[state.subProduct.name],
                    value: ''
                }
            }));
            setOptions(state.subProduct.name, [])

        }
    }, [state.product.value])
    useEffect(() => {
        if (state.warehouse.value) {
            v2_gates(state.warehouse.value).then((res) => {
                if (res.status) {
                    setOptions(state.gateNumber.name, res.result);
                }
            })
        }
        else {
            setState(prevState => ({
                ...prevState,
                [state.gateNumber.name]: {
                    ...prevState[state.gateNumber.name],
                    value: ''
                }
            }));
            setOptions(state.gateNumber.name, [])

        }
    }, [state.warehouse.value])


    useEffect(() => {
        if (state.transporter.value) {
            v2_transporter_vehicles(state.transporter.value).then((res) => {
                if (res.status) {
                    setOptions(state.vehicle.name, res.result)
                }
            })
        }
        else {
            setState(prevState => ({
                ...prevState,
                [state.vehicle.name]: {
                    ...prevState[state.vehicle.name],
                    value: ''
                }
            }));
            setOptions(state.vehicle.name, [])

        }
    }, [state.transporter.value])
    return (
        <React.Fragment>
            <h4 className="card-title mb-4">{props.title}</h4>
            <div id="basic-example">
                <section>
                    <form onSubmit={onSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-date-input">
                                        Date <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type="date"
                                        className={state.date.error ? "form-control is-invalid" : "form-control"}
                                        id="basicpill-date-input"
                                        name={state.date.name}
                                        value={state.date.value}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-voucherType-input">
                                        Voucher Type <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        id="basicpill-voucherType-input"
                                        className={state.voucherType.error ? "form-select is-invalid" : "form-select"}
                                        defaultValue={""}
                                        name={state.voucherType.name}
                                        value={state.voucherType.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Voucher Type</option>
                                        {state.voucherType.options.map((item) => {
                                            return (<option value={item.ID}>{item.NAME}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-warehouse-input">
                                        Warehouse Number <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        id="basicpill-warehouse-input"
                                        className={state.warehouse.error ? "form-select is-invalid" : "form-select"}
                                        name={state.warehouse.name}
                                        value={state.warehouse.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Warehouse</option>
                                        {state.warehouse.options.map((item) => {
                                            return (<option value={item.ID}>{item.NAME}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-gateNumber-input">
                                        Gate Number <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        id="basicpill-gateNumber-input"
                                        className={state.gateNumber.error ? "form-select is-invalid" : "form-select"}
                                        name={state.gateNumber.name}
                                        value={state.gateNumber.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Gate</option>
                                        {state.gateNumber.options.map((item) => {
                                            return (<option value={item.ID}>{item.NAME}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-invoiceNumber-input">
                                        Invoice Number <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        className={state.invoiceNumber.error ? "form-control is-invalid" : "form-control"}
                                        id="basicpill-invoiceNumber-input"
                                        name={state.invoiceNumber.name}
                                        value={state.invoiceNumber.value}
                                        placeholder='Enter Invoice Number'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-transporter-input">
                                        Transporter <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        id="basicpill-transporter-input"
                                        className={state.transporter.error ? "form-select is-invalid" : "form-select"}
                                        defaultValue={""}
                                        name={state.transporter.name}
                                        value={state.transporter.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Transporter</option>
                                        {state.transporter.options.map((item) => {
                                            return (<option value={item.ID}>{item.NAME}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-vehicle-input">
                                        Vehicle <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        id="basicpill-vehicle-input"
                                        className={state.vehicle.error ? "form-select is-invalid" : "form-select"}
                                        defaultValue={""}
                                        name={state.vehicle.name}
                                        value={state.vehicle.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Vehicle</option>
                                        {state.vehicle.options.map((item) => {
                                            return (<option value={item.ID}>{item.NAME}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-product-input">
                                        Product <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        id="basicpill-product-input"
                                        className={state.product.error ? "form-select is-invalid" : "form-select"}
                                        defaultValue={""}
                                        name={state.product.name}
                                        value={state.product.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Product</option>
                                        {state.product.options.map((item) => {
                                            return (<option value={item.ID}>{item.NAME}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-subProduct-input">
                                        Sub Product <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        id="basicpill-subProduct-input"
                                        className={state.subProduct.error ? "form-select is-invalid" : "form-select"}
                                        defaultValue={""}
                                        name={state.subProduct.name}
                                        value={state.subProduct.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Sub Product</option>
                                        {state.subProduct.options.map((item) => {
                                            return (<option value={item.ID}>{item.NAME}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-challanWeight-input">
                                        Challan Weight (in Kg) <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type='number'
                                        id="basicpill-challanWeight-input"
                                        className={state.challanWeight.error ? "form-control is-invalid" : "form-control"}
                                        name={state.challanWeight.name}
                                        value={state.challanWeight.value}
                                        placeholder='Enter Weight'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-invoiceRate-input">
                                        Invoice Rate (in Rs) <span className='text-danger'>*</span>
                                    </label>
                                    <input
                                        type='number'
                                        id="basicpill-invoiceRate-input"
                                        className={state.invoiceRate.error ? "form-control is-invalid" : "form-control"}
                                        name={state.invoiceRate.name}
                                        value={state.invoiceRate.value}
                                        placeholder='Enter Rate'
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-party-input">
                                        Party Name <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        id="basicpill-party-input"
                                        className={state.party.error ? "form-select is-invalid" : "form-select"}
                                        defaultValue={""}
                                        name={state.party.name}
                                        value={state.party.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Voucher Type</option>
                                        {state.party.options.map((item) => {
                                            return (<option value={item.ID}>{item.COMPANY_NAME}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-mill-input">
                                        Mill <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        id="basicpill-mill-input"
                                        className={state.mill.error ? "form-select is-invalid" : "form-select"}
                                        defaultValue={""}
                                        name={state.mill.name}
                                        value={state.mill.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Voucher Type</option>
                                        {state.mill.options.map((item) => {
                                            return (<option value={item.ID}>{item.COMPANY_NAME}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <label htmlFor="basicpill-source-input">
                                        Source <span className='text-danger'>*</span>
                                    </label>
                                    <select
                                        id="basicpill-source-input"
                                        className={state.source.error ? "form-select is-invalid" : "form-select"}
                                        defaultValue={""}
                                        name={state.source.name}
                                        value={state.source.value}
                                        onChange={onChange}
                                    >
                                        <option value={''}>Select Voucher Type</option>
                                        {state.source.options.map((item) => {
                                            return (<option value={item.ID}>{item.COMPANY_NAME}</option>)
                                        })}
                                    </select>
                                </div>
                            </div>




                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="mb-3">
                                    <button
                                        type='submit'
                                        className="btn btn-success waves-effect btn-label waves-light me-2"
                                    >
                                        <i className="bx bx-check-double label-icon" />{" "}
                                        {props.buttonName}
                                    </button>
                                    <button
                                        type='reset'
                                        className="btn btn-danger waves-effect btn-label waves-light"
                                        onClick={() => { props.onCancel() }}
                                    >
                                        <i className="bx bx-block label-icon " /> Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </React.Fragment>

    )
}

export default Form