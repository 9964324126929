import axios from 'axios'
import { getAuthHeader } from '../../utils';

export const v1_login = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/login`;
    return axios.post(url, model).then((res) => {
        return res.data;
    })
}


export const v1_unlock = async (model) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/unlock`;
    return axios.post(url, model).then((res) => {
        return res.data;
    })
}


export const v3_activity_logs = async (limit, page, fromDate, toDate) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v1/activity-logs/${limit}/${page}/${fromDate}/${toDate}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}