import axios from 'axios'
import { getAuthHeader } from '../../utils';

export const v2_products = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/product`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v2_sub_products = async (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/sub-product/${id}`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}


export const v2_product_count = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/v2/count/product`;
    return axios.get(url, getAuthHeader()).then((res) => {
        return res.data;
    })
}